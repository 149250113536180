<template>

                    <div>
                        <div class="logo_img">
                            <!-- <img src="../assets/img/rehab.png" alt=""> -->
                            
                        </div>
                    </div>
             
    
    </template>
    
    
    <script>
    
    export default {
    
        name: 'vue-component' 
    
    
    }
    </script>
    
    
    <style scoped>
    
    .logo_img{
        width: 40px;
        height: 40px;
       
    }
    
    .logo_img img {
        width: 100%;
        height: 100%;
    }
    
    
    
    </style>