<template>
  
   <div class="home"  >
     
    <div class="front-page">
        <div class="home-title"   data-aos='zoom-in-up'
    data-aos-duration='1500'>
            <!-- <h1 >
          Automated  Physiotherapy <br><span>Diagnosis</span>, <span>Self-Triage</span> & <span>TeleRehab</span> <br>System 
         </h1>
         -->
         <h1 style="color: white;
; font-weight: 500; ">
         AI-assisted Musculoskeletal Triage Tool 
         </h1>
  <p style=" font-family: 'Sora' ;opacity: .6; ">Safety
             <span style="color:#00ce0f; font-family: 'Sora'; ">First</span> 
             <br> <br>
      </p> 
   
        
    

       <div class="home-button"  data-aos='fade-right'
    data-aos-duration='1500'>
        
        <button @click="$router.push('/gateway')" >
            <router-link to="/gateway"> Start here 
              
         </router-link>

         <span><img src="../assets/img/right-arrow-svgrepo-com.svg" alt="" srcset=""></span>
        
        </button>
       
       </div>

        </div>

       <div class="home-img">
        
        <!-- <img src="../assets/img/homepic.png" alt=""> -->
        <img src="../assets/img/trial.jpg" alt="" srcset="">
        <!-- <img style="min-width: 800px;" src="../assets/img/blackdoc.png" alt=""> -->
        
    
       </div>
    </div>


    <!-- <div class="home-step" >
               
        <h2 style="color: black; font-weight: 500;">How Does It Work?</h2>      


                <div class="steps">
                <div class="step" id="one"  data-aos='fade-up'
    data-aos-duration='1200'>
                    <div class="all-img">
                    <div class="img">
                                    <img src="../assets/img/claudia-wolff-owBcefxgrIE-unsplash_11zon.jpg" alt="" srcset="">
                                    </div>
                    </div>
                                

                                    <h3>Check Symptoms</h3>
                                    <p>Our triage tool will prompt you to choose the affected body part related to your symptoms and will ask you specific questions based on that body part.
                                         You can only select one body part at a time from the twelve clickable options.
          </p>
                    </div>


                    <div class="step" id="two"  data-aos='fade-right'
    data-aos-duration='1200'>
                    <div class="all-img">
                        <div class="img">  
                                    <img src="../assets/img/christin-hume-Hcfwew744z4-unsplash_11zon.jpg" alt="" srcset="">
                                </div> 
                    </div>            
                    
                                
                                <h3>Get  Diagnosis</h3>
                                    <p>  In few seconds, you will get a clinical impression based on your symptoms. If symptoms are serious, you'll be advised to go to the hospital immediately.
                                         You will also download a PDF summary of your symptoms and details to help reduce waiting time  </p> </div>


                    <div class="step" id="three"  data-aos='fade-up'
    data-aos-duration='1200'>
                        <div class="all-img">
                        <div class="img">  
                                    <img src="../assets/img/pexels-anna-shvets-4225920_11zon.jpg" alt="" srcset="">
                                </div>
                        </div> 
                                
                                    <h3>Get Connected</h3>
                                    <p>After receiving a clinical impression, you can forward the report to any email, send a request for a verified rehabilitation expert based on your report. You can also download the report for various uses. 
                                       
                                      
                                    </p>
                    </div>
                    



</div>


             
        </div> -->


     

   <!-- <div class="physio-section" >
            <h4 >Are You A Licensed PT, OT, ST, O&P ? </h4>
            <div class="physio" >
                    <div class="physio-text"  data-aos='fade-left'
    data-aos-duration='1500'>
                        <div class="physio-content" >
                        
                            <div class="pt-step">
                                        <div class="pt-step-img">
                                                <img src="../assets/icon/PT1.svg" alt="" srcset="">
                                        </div>
                                        <div class="pt-step-info">
                                            <p>Create an account and upload your valid license</p>
                                        </div>
                            </div>
                            <div class="pt-step">
                                        <div class="pt-step-img">
                                                <img src="../assets/icon/PT2.svg" alt="" srcset="">
                                        </div>
                                        <div class="pt-step-info">
                                            <p>Get connected to patients and earn in the process </p>
                                        </div>
                            </div>
                            <div class="pt-step">
                                        <div class="pt-step-img">
                                                <img src="../assets/icon/PT3.svg" alt="" srcset="">
                                        </div>
                                        <div class="pt-step-info">
                                            <p>Get rated by patients and rise up the Rehabify board </p>
                                        </div>
                            </div>
                            <div class="pt-step">
                                        <div class="pt-step-img">
                                                <img src="../assets/icon/PT4.svg" alt="" srcset="">
                                        </div>
                                        <div class="pt-step-info">
                                            <p>Get your Rehabify virtual rehab certificate anytime</p>
                                        </div>
                            </div>


                        </div>
                                <div class="home-button">
        
        <button  data-aos='fade-right'
    data-aos-duration='1000'>
            <router-link to="/signup"> Register
              
         </router-link>

         <span><img src="../assets/img/right-arrow-svgrepo-com.svg" alt="" srcset=""></span>
        </button>
       
       </div>
             
                        </div>


                      
                        <div id="physio-img"  data-aos='zoom-out-down'
    data-aos-duration='1500'>
                            <img src="../assets/img/physiocropped-removebg-preview.png" alt="" srcset="">
                        </div>
            </div>

                


        </div> -->

    <div lass="last-section">
         <!-- <h4 class="faq-title" style="background-color: white; color: black; font-family: 'Sora' ;font-weight: 500;">Frequently Asked Questions</h4> -->

    <div lass="faq-section"  data-aos='fade-up'
    data-aos-duration='1000'>

            <!-- <div class="faq">
                 <div class="chat" >
                    <div class="chat-img">
                            <div class="pt-img">
                                <img src="../assets/img/blackdoc1.png" alt="" srcset="">
                            </div>

                        <div class="chat-img-phone">
                             <div class="all-chat">

                                     <div id="chat2">
                                                <div id="one">
                                                  
                                                </div>
                                                <p>Hello my name is Ebiere Stacy </p>
                                       </div>

                                    <div id="chat">
                                                <div id="two">
                                                    
                                                </div>
                                                <p>Hello there, how can I help? </p>
                                    </div>

                                    <div id="chat2">
                                                <div id="one">
                                                   
                                                </div>
                                                <p>I have had a serious back pain for months..</p>
                                    </div>

                                    <div id="chat">
                                                  <div id="two">
                                                   
                                                </div>
                                                <p>Can you quickly fill this form for me\/ </p>
                                    </div>
                                    </div>


                        </div>
                    </div>



            </div> -->
            <!-- </div> --> 

            <div clas="faq-content">
                
                    <!-- <div class="content">
                            <div class="each-content">
                                            <div class="each-question">
                                                <p >Can I trust rehabify with my data ? </p>
                                    <div class="dropdown-img">
                                        <img @click="showFaq" id="One" :src="(this.showOne? require('../assets/icon/circle-arrow-up-svgrepo-com.svg'): require('../assets/icon/circle-arrow-down-svgrepo-com.svg'))" alt="" srcset="">
                                    </div>

                                            </div>

                                    <p class="each-answer" v-show="showOne">Of course! you can. We stated in our privacy policy that we will ensure the safety of your information </p>
                            </div>


                            <div class="each-content">
                                            <div class="each-question">
                                                <p>How do I make payment for Consultation ? </p>
                                    <div class="dropdown-img">
                                        <img @click="showFaq" id="Two" :src="(this.showTwo? require('../assets/icon/circle-arrow-up-svgrepo-com.svg'): require('../assets/icon/circle-arrow-down-svgrepo-com.svg'))" alt="" srcset="">
                                    </div>

                                            </div>

                                    <p class="each-answer" v-show="showTwo" >Our team will advice on diverse ways you can make payment
                                        
                                        
                                        </p>
                            </div>


                            <div class="each-content">
                                            <div class="each-question">
                                                <p>How can I contact Rehabify through Email ? </p>
                                    <div class="dropdown-img">
                                        <img @click="showFaq" id="Three" :src="(this.showThree? require('../assets/icon/circle-arrow-up-svgrepo-com.svg'): require('../assets/icon/circle-arrow-down-svgrepo-com.svg'))" alt="" srcset="">
                                   </div>

                                            </div>

                                    <p class="each-answer" v-show="showThree" id="Three">Contact info@rehabifyng.com  
 </p>
                            </div>

                            <div class="each-content">
                                            <div class="each-question">
                                                <p >When are we launching rehabify? </p>
                                    <div class="dropdown-img">
                                        <img @click="showFaq" id="Four" :src="(this.showFour? require('../assets/icon/circle-arrow-up-svgrepo-com.svg'): require('../assets/icon/circle-arrow-down-svgrepo-com.svg'))" alt="" srcset="">
                                    </div>

                                            </div>

                                    <p class="each-answer" v-show="showFour">We are launching officially in April, 2024 </p>
                            </div>


                            <div class="each-content">
                                            <div class="each-question">
                                                <p >Is the certifing board aware of Rehabify?</p>
                                    <div class="dropdown-img">
                                        <img @click="showFaq" id="Five" :src="(this.showFive? require('../assets/icon/circle-arrow-up-svgrepo-com.svg'): require('../assets/icon/circle-arrow-down-svgrepo-com.svg'))" alt="" srcset="">
                                    </div>

                                            </div>

                                    <p class="each-answer" v-show="showFive">Yes absolutely, We are in partnership with the regulatory board for rehabilitation experts in Nigeria (MRTB)</p>
                            </div>

                            



                            
                    </div>  -->

                    <!-- <div class="faq-pic">
                        <img src="../assets/img/faqpic.png" alt="" srcset="">
                    </div> -->

            </div> 
        




        </div>

<!--         
        <footer>
     
        <div class="brand">
                 <div class="footer-logo">
                        <logoVue/>
                        <p> Rehabify</p>
                        
                    </div>

              <div class="footer-paragraph">
                        <p>
                        Lorem,  quo blanditiis, repellat repudiandae atque repellendus natus officiis cupiditate aperiam, accusantium dicta sequi obcaecati placeat explicabo.
                        </p>
             </div>

             <div class="social-media">
                <a href="">
                    <span id="instagram"><img src="../assets/icon/social/instagram-svgrepo-com.svg" alt="instagram icon" srcset=""></span>
                </a>
                 <a href="">
                    <span id="facebook"><img src="../assets/icon/social/linkedin-svgrepo-com.svg" alt="linkedin icon" srcset=""></span>
                 </a>
        
                <a href="">
                    <span id="instagram"><img src="../assets/icon/social/twitter-svgrepo-com.svg" alt="twitter icon" srcset=""></span>
                
                </a>
                 <a href="">
                        <span id="gmail"><img src="../assets/icon/social/mail-svgrepo-com.svg" alt="gmail icon" srcset=""></span>
            
                    </a>
            </div>
         </div>

     <div class="footer-link">
        <div  class="each-link">
            <div class="title">
            <p> Company</p>
         </div>

         <div class="links">
             <li>
                <router-link to="/about">Why Rehabify</router-link>
                 
             </li>
             <li>
                
                 <router-link to="/about">About us</router-link>
                 
             </li>
             <li>
                 <router-link  to="/privacy-policy">Privacy policy</router-link>
                 
             </li>
             <li>
               
                 <router-link to="/about">Partner with us</router-link>
                 
             </li>
         </div>
        </div>
     
    <div class="each-link">
    <div class="title">
             <p>Resources</p>
         </div>

         <div class="links">
             <li>  
                 <router-link to="/about">Physiotherapist Support</router-link>
                       
             </li>
             <li>      
                 <router-link to="/about">Patient Support</router-link>
                 
             </li>
             <li>
                 <router-link to="/about">report an Incident</router-link>
                 
             </li>
             <li>
                 <router-link to="/about">TeleRehab Certification</router-link>
                 
             </li>
         </div>
</div>
         
     </div>

     
     


            </footer> -->

        </div>
        


   </div>


</template>


<script>
// import logoVue from '../components/logo.vue';


export default {

name: 'Home-component',
components: {
//    logoVue
}, 

data(){
    return{
        showOne: false,
        showTwo: false,
        showThree: false,
        showFour: false,
        showFive: false
    }
},

methods:{

    // shows and hide the FAQ 
    showFaq(e){
        let id = e.target.id;      
        this[`show${id}`]= !this[`show${id}`]
    

},



}

}
</script>



<style scoped>

#physio-title{
   color: var(--colorA);
}


</style>