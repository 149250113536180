<template>

    <div class="blog">
 
 Blog
 
 
 
    </div>
 
 </template>
 
 
 <script>

 export default {
 
 name: 'blog-component' ,
 components:{
   
 },
 
 
 }
 </script>
 
 
 <style scoped>
 
 
 
 </style>