
<!-- eslint-disable -->
<template>


    <div class="download">
         
      <div class="symptom-container">


            <div class="first-part">
            <h1>
             
            </h1>
            </div>


         <div class="second-part">

         </div>


            <div class="main-part">




               
<div class="gateway-title">
                <p>
                    Download  Report 
                </p>
</div>

<div class="gateway-options">


                     <div class="option">
                     <div class="option-title">
                     <p id="title">Patient Report</p>
                     <p id="details">This report is for patients only.</p>
                     </div>

                     <div class="option-img" @click="downloadpatient()" >
                     <img  src="../assets/icon/downloadbold.svg" alt="" srcset="">  

                     </div>

                     </div>

                     <div class="option">
                     <div class="option-title">
                     <p id="title">Medical Report</p>
                     <p id="details">This detailed report is for medical professionals only </p>
                     </div>

                     <div class="option-img" @click="downloadPhysio()" >
                     <img src="../assets/icon/downloadbold.svg" alt="" srcset="">  

                     </div>

                  

                      </div>

</div>

               </div>

                  <div class="report">

                    <div ref="patient" id="patient"  style="display:none;">
                      <patientReport  
                      :User = this.currentUser 
                      :Symptoms="this.currentSymptoms"
                      :Diagnosis="this.currentDiagnosis" />
                    </div>

                    <div ref="physio" v-show="false">
                      <PhysioReport
                      :User = this.currentUser 
                      :Symptoms="this.currentSymptoms"
                      :Diagnosis="this.currentDiagnosis" 
                      />
                    </div>
                  </div>
 






    </div>
 
 
    </div>
 
 </template>
 
 
 <script>

import patientReport from '../components/patientReport.vue'
import html2pdf from 'html2pdf.js'
import PhysioReport from '../components/physioReport.vue'
 
 export default {
 
 name: 'download-component' ,
 components:{
    patientReport,
    PhysioReport,
   
},
data(){
  return{
   currentUser: null,
   currentSymptoms:null,
   currentDiagnosis: null

  }
},

 methods:{

   downloadpatient(){
    
    this.$emit('downloadClicked')
    this.$refs.patient.style.display = "block";


     html2pdf(this.$refs.patient, {
filename: `Rehabify Patient's report`,
margin: 0,
image: {type: 'jpeg', quality: .98},
jsPDF: {unit: 'mm', format : 'a4', orientation: 'portrait', putOnlyUsedFonts: true},
html2canvas: { dpi: 192, letterRendering: true, scale: 2 },

     })

     setTimeout((()=>{
      this.$refs.patient.style.display = "none";
     }), -100000)

 
// document.querySelector("#patient").style.display = "none";
   },
   downloadPhysio(){
   this.gatherData()

    this.$refs.physio.style.display = "block";


html2pdf(this.$refs.physio, {
filename: `Rehabify Medical Report`,
margin: 0,
image: {type: 'jpeg', quality: .98},
jsPDF: {unit: 'mm', format : 'a4', orientation: 'portrait', putOnlyUsedFonts: true},
html2canvas: { dpi: 192, letterRendering: true, scale: 2 },

})

setTimeout((()=>{
 this.$refs.physio.style.display = "none";
}), -100000)


   },

   // gather data from localstorage to put into the doc
   gatherData(){

    let foundUser =  localStorage.getItem('User')?  localStorage.getItem('User'): 'User not found'
    this.currentUser = foundUser


    let foundSymptoms =  localStorage.getItem('Symptoms')?  localStorage.getItem('Symptoms'): 'User not found'
   this.currentSymptoms= foundSymptoms

   let foundDiagnosis =  localStorage.getItem('Diagnosis')?  localStorage.getItem('Diagnosis'): 'User not found'
   this.currentDiagnosis= foundDiagnosis


   console.log(foundDiagnosis)
   console.log(foundUser)
   },


 }


 
 
 
 }
 </script>
 
 
 <style scoped>



 .download .gateway-options{
   width: 85%;
   margin: auto;
 }
 .download .options{

width: 80%;
margin: auto;
 
 
 }

 .download .gateway-title {
   background-color: var(--colorB);
   padding: 10px;
 }
 .download .gateway-title p{
   text-align: center;
   font-weight: 700;
   color: white
   ;
 }
 
 .download img{
   filter: invert(0);
   color: white;
   width: 30px;
   filter: invert(92%) sepia(55%) saturate(3239%) hue-rotate(275deg) brightness(97%) contrast(92%);
 }
 
 </style>