

<template>


          
    <div class="profile-page">
       <div class="display" v-show="this.showDashboard"> 


                <div class="display-picture">
                        <div class="picture">
                                <img src="../assets/img/profilepic3.jpg" alt="" srcset="">
                                
                                <div class="change">
                                       <button> Add a Photo</button>
                                </div>
                        </div>

                        <div class="text">
                                <div class="name">
                               <span>{{FirstName}}</span>
                               <span>{{LastName}}</span>
                                </div>
                                <p id="physio">Physiotherapist</p>
                                <div class="about">
                                      <p>
                                   {{ About }} 
                                 </p>
                                </div>
                        </div>
                </div>

                <div class="display-button">
                        <div class="button">
                                
                            <div class="display-option">
                                <div class="option-title">
                                        <p id="title">
                                           Browse Exercises
                                        </p>
                                        <p id="details">Assign your patient our pre-recorded exercises</p>
                                </div>

                                <div class="option-img" @click="showSignup ">
                                        <router-link to= '/'>  <img  id="arrowimg" src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  </router-link>
                                
                                </div>
                            
                            </div>

                            <div class="display-option">
                                <div class="option-title">
                                        <p id="title">
                                            My Rehabify Report
                                        </p>
                                        <p id="details">Browse through your past rehabify reports</p>
                                </div>

                                <div class="option-img" @click="showSignup ">
                                        <router-link to='/profile'>  <img  id="arrowimg" src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  </router-link>
                                
                                </div>
                            
                            </div>

                            <div class="display-option">
                                <div class="option-title">
                                        <p id="title">
                                            New Diagnosis 
                                        </p>
                                        <p id="details">Find the clinical impression for a new patient</p>
                                </div>

                                <div class="option-img" @click="showSignup ">
                          <router-link to='/symptom/ademola'>  <img  id="arrowimg" src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  </router-link>
                                </div>
                            
                            </div>
                        </div>
                </div>




        </div>
       <div class="chat" v-show="this.showChat">
        Coming soon
</div>
       <div class="wallet" v-show="this.showWallet">
        Coming soon
</div>
       <div class="user" v-show="this.showProfile">
        Coming soon
               <div class="picture">picture</div>
               <div class="info">info</div>
       </div>
        </div>

   



<!-- <div>  {{ User.email }} </div>
<div> {{ User.name }}</div>
-->
</template>



<script>




export default {

name: 'Physio-component',
components:{


},
props:{
showProfile: Boolean,
showDashboard: Boolean,
showWallet: Boolean,
showChat: Boolean,

},
data(){
return {

PhysioPlatform: null,
Language : null,
Email: null,
Phone: null,
Country: null,
Qualification: null,
Gender: null,
DOB: null,
FirstName: '',
LastName: '',
About: null,
Address: null,
subscribed: false,
verified: false,
finalRating: null



}

},



created(){
        let user=  JSON.parse(localStorage.getItem('User'));
     

this.PhysioPlatform= user.PhysioPlatform? user.PhysioPlatform: '',
this.Language= user.Language? user.Language: '',
this.Email= user.Email? user.Email: '',
this.Phone= user.Phone,
this.Country= user.Country,
this.Qualification= user.Qualification,
this.Gender=  user.Gender,
this.age= user.age,
this.FirstName= user.FirstName,
this.LastName=  user.LastName,
this.About= user.About
this.Address= user.Address? user.Address: '',
this.subscribed= user.subscribed,
this.verified= user.verified,
this.finalRating= user.finalRating



},
methods:{
},

beforeEnter(){

}
}
</script>


<style scoped>



</style>

