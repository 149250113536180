import Vuex from 'vuex'
import newUser from './module/newUser'
import currentUser from './module/currentUser'
import createPersistedState from 'vuex-persistedstate'
import Created from './module/Created'



export const store = new Vuex.Store({
   modules:{
    newUser,
    currentUser,
    Created
   },
   plugins: [createPersistedState({
      storage: window.sessionStorage,
  })]
    })