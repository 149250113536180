<template>

   <div class="find-a-physio" style="">
      <h3 style="margin-bottom: 50px; text-align: center;">Please provide patient's details below</h3>

<form action=""> 
   <div class="Name">
                    <label for="firstname">Full Name</label>
                    <input  type=""  autocomplete="Full-Name" id="fullname">
                   
                </div>

                <div class="email">
                    <label for="firstname">Phone number</label>
                    <input  type="number"  v-model=" userEmail"  autocapitalize="characters" id="firstname">
            
                </div>

                <div class="address">
                    <label for="firstname">Address</label>
                    <input  type=""  v-model=" userEmail"   autocapitalize="characters" id="firstname">
            
                </div>

                <div class="email">
                    <label for="firstname">Email</label>
                    <input  type="email"  v-model=" userEmail"   autocomplete="email" autocapitalize="characters" id="firstname">
            
                </div>

                <div class="Description" >
                    <label for="Description">Short description of request</label>
                    <input  type="" >
                </div>

                <div class="button" style="display: flex; justify-content: center; margin-top: 30px; " >
                 
               <button type="submit" @click="requestMade" style="max-width: 50%; min-height: 20px; padding: 15px;">
                 <!-- <p > {{MessageSent? 'Find me an expert': 'We will get back to you' }}</p>  -->
                 <p   v-if="MessageSent">Find me an expert</p> 
                 <p v-if="!MessageSent">Sent! We will get back to you</p> 
               </button>
            </div>


</form>



   </div>

</template>


<script>

export default {

name: 'findaphysio-component' ,
components:{
  
},
data(){
    return {
        MessageSent:true,
    }
   },

   methods:{

      requestMade(){
         
         setInterval(
   
            this.MessageSent= false
         , 1000)

    

      }
   }

}
</script>


<style scoped>
.find-a-physio{
   padding: 10px;
}

form div{
   margin-bottom: 10px;
}



</style>