<template>


    <div class="login">
        
      <div class="login-error" v-show="Error">
      <p>{{ loginErrorMessage }}</p>
      </div>

       <div class="login-success" id="success" v-show="Success">
          <p> Check your email inbox</p>
       </div>
    
    
    <div class="form" >
    
    <div id="form">
       <div class="form-content">
          <h1 style="margin-top: 20px;">TYPE IN YOUR REGISTERED EMAIL</h1>
               <form  @submit.prevent="sendResetEmail" >
    
                <div class="email" style="margin: 70px 0px;">
                       
                        <input  type="email" placeholder="Email" v-model=" resetEmail"   autocomplete="email" autocapitalize="characters" id="firstname">
                        
                    </div>
                  
                   
       
                <div class="button">
                   <button type="submit">RESET PASSWORD</button>
                </div>
                
    
                <p id="signup">Already have an account? <b @click="$router.push('/login')"> Login into account</b></p>
             
         </form>
    
             </div>
    
    
    </div>
    
    
    </div>
     
     
    
    
    
    </div>
     
     </template>
     
     
     <script>
    import { auth, sendPasswordResetEmail} from '@/main.js'
    

     export default {
     
     name: 'login-component' ,
     components:{
       
     },
     data(){
       return{
        resetEmail: null,
          Success:false,
          Error: false,
          passwordError:false
       }
     },
    
     methods: {
    

       sendResetEmail(){
    

               
         sendPasswordResetEmail(auth, this.resetEmail)
                         .then(()=> {
    
                     
                            this.Success= true
                             
                            setTimeout(()=>{
                             
                              
                               window.location.replace('/login')
                                                   
                            },2000)
                           
                          
                         })
    
                         .catch((err)=> {
                         
                            let displayError= setInterval(()=>{
                                  
                               let errcode = err.code
                               this.Error = true
                                           if (errcode === 'auth/wrong-password'){
                                                 this.loginErrorMessage = 'Wrong Password Provided'
                                           }
                                           if (errcode === 'auth/user-not-found' || errcode === 'auth/user-disabled' || errcode === 'auth/invalid-email'){
                                                 this.loginErrorMessage = 'User not found'
                                           }
                                },1000);
                                        
                                setTimeout(()=>{
                                            clearInterval(displayError)
                                            this.Error= false
                                            this.loginErrorMessage = ''
          
                               },3000)
                                      
                            
                         })  
    
    
    },
    
    
    
      
    
     }
     
     
     }
     </script>
     
     
     <style scoped>
     
    
     </style>