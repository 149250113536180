

<template>
   
 

       <div class="profile">
 
          

             <div class="profile-link">
                <span ref="Dashboard" @click="showCategory" class="active">Dashboard</span>
                <span ref="Chat" @click="showCategory">Chat</span>
                <span ref="Wallet" @click="showCategory">Wallet</span>
                <span ref="Profile" @click="showCategory">Profile</span>
             </div>
             
             <patientProfile 
             v-show="this.Ispatient"
               :showDashboard="this.showDashboard" 
               :showChat="this.showChat"
               :showProfile= "this.showProfile"  
               :showWallet="this.showWallet" 
           
               />

               <physioProfile 
               v-show="this.Isphysio"
               :showDashboard="this.showDashboard" 
               :showChat="this.showChat"
               :showProfile= "this.showProfile"  
               :showWallet="this.showWallet" 
           
               />
                
 
          
 
        </div> 

   <!-- <div>  {{ User.email }} </div>
      <div> {{ User.name }}</div>
     -->
 </template>


 
 
 
 <script>



import patientProfile from '../components/patientprofile.vue'
import physioProfile from '../components/physioProfile.vue'
// import {auth, db, getDoc, doc } from '../main'




 export default {
 
 name: 'Profile-component',
 components:{
   patientProfile,
   physioProfile

   
 },
 data(){
    return {
        showProfile: false,
        showDashboard: true,
        showWallet: false,
        showChat: false,
        
      //   showRehab: false,


    User:null,
    Isphysio: false,
    Ispatient: false 

    }
 },

  created(){
   let currentUser=  JSON.parse(localStorage.getItem('User'));


   if (currentUser.Category === 'Physiotherapist'){
      this.Isphysio = true
   }
   else{
      this.Ispatient = true
   }



},

  
methods:{
  
   showCategory(e){

     
         let text = e.target.innerHTML
         let allCategory= {
            Dashboard: this.showDashboard,
            Wallet: this.showWallet,
            Profile: this.showProfile,
            Chat: this.showChat
         }

        for(let each in allCategory){
            if (each === text){
               this[`show${each}`] = true

               this.$refs[`${each}`].classList.add('active')

            }
            else{
               this[`show${each}`] = false
               this.$refs[`${each}`].classList.remove('active')
               
            }
        }
         
   }
},
 

 }
 </script>
 
 
 <style scoped>

 
 
 </style>

