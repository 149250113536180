
import  '../src/assets/css/home.css'
import  '../src/assets/css/symptoms.css'
import  '../src/assets/css/gateway.css'
import  '../src/assets/css/login.css'
import  '../src/assets/css/profile.css'
import  '../src/assets/css/small.css'
import  '../src/assets/css/medium.css'
import  '../src/assets/css/large.css'
import  '../src/assets/css/extra-large.css'

// import firebase from '@/firebase'

import router from './route'
import {store} from './store/index'

import 'aos/dist/aos.css'

import * as Vue from 'vue';
import App from './App.vue';
import { initializeApp } from 'firebase/app';
import { getAuth,  onAuthStateChanged,sendPasswordResetEmail, signOut, sendEmailVerification, sendSignInLinkToEmail, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

Vue.createApp(App).use(router). use(store).mount('#app');


  const firebaseConfig = {
    apiKey: "AIzaSyDmbI4EoSwspsm2WxUfyEL9x02lkKhyelQ",
    authDomain: "rehabify-601d2.firebaseapp.com",
    databaseURL: "https://rehabify-601d2-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rehabify-601d2",
    storageBucket: "rehabify-601d2.appspot.com",
    messagingSenderId: "509460652120",
    appId: "1:509460652120:web:00e81b66fe431ee5d5aa2d",
    measurementId: "G-F2QPTSCWFG"
  };
  
 
  // // Initialize Firebase

   const app= initializeApp(firebaseConfig);

  
const auth = getAuth(app);
const db = getFirestore(app)
 
  export { auth, db, doc, getDoc, setDoc,sendPasswordResetEmail, onAuthStateChanged, signOut, sendEmailVerification, sendSignInLinkToEmail,  createUserWithEmailAndPassword,signInWithEmailAndPassword } 
  




