
const state = {
 
    currentUser: {},
    currentDiagnosis: {},
    isAuthenticated: false

}

const getters = {
    getAuth(){
        return state.isAuthenticated
    },
    getCurrentUser(){
        return state.currentUser
    }
    
}

const actions = {

    // fetch current User's data from the Database- Signed up User
         getUser({commit}, loginData){   
            

            commit ('currentUser', loginData)
        

             },
        
     // fetch current User's data from the gateway - Single time User
        getRandom({commit}, user){
     
            commit ('randomUser', user)
           
            localStorage.setItem('User', JSON.stringify(user))

             },

        // One time authetication - not in use for now
        //  updateAuth({commit}){
                  
        //        let update = true
        //         commit ('changeAuth', update )

        //         // let random= (Math.random() +1).toString(36).substring(8)
        //         localStorage.setItem('auth', true )
        //      },
             

          getSymptoms({commit}, symptoms){
                 // post diagnosis to user's database
               
               
                  
                 localStorage.setItem('Symptoms', JSON.stringify(symptoms) )


    commit ('saveDiagnosis', diagnosis)
                     
            }   

}

const mutations = {
    currentUser: (state, user)=> {
        state.currentUser =  user
        state.isAuthenticated = true
        
    },
    randomUser: (state, user) => (state.currentUser = user),
    saveDiagnosis: (state, diagnosis) => (state.currentDiagnosis = diagnosis),
    changeAuth: (state)=>{ 
        
			// Check if the ID exists
			if(sessionStorage.getItem('vuex')) {
				// Replace the state object with the stored item
				state.isAuthenticated = JSON.parse(sessionStorage.getItem('vuex')).currentUser.isAuthenticated
            }
        
          
        }

    
}






export default {
    state,
    getters,
    actions,
    mutations
}