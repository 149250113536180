<template>

    <div class="symptom-container">
    
    
    
                <div class="first-part">
                
                </div>
    
    
                <div class="second-part">
    
                </div>
    
    <!-- main part of the symptom -->
    
            <div class="main-part">
            
                        <div class="first-main" style="background-color: red; padding:20px;">
                                <h2>
                                <span >Serious</span> Pathology Suspected 
                                </h2>
                                  <p style="opacity: 1; color: wheat;">
                                    We need you to stay calm and follow the instructions below
                                  </p>
                        </div>
    
                    
                        <div class="second-main" id="urgent-page" >
                                     
                <p id="urgent">Based on your response so far, we believe that you need to see a doctor as soon as possible. <br> Download the summary note below and bring it with you to the hospital. </p>
    

            <div class="gateway-options">


            <div class="option">
            <div class="option-title">
            <p id="title" style="color:white; margin-bottom: 10px; font-weight: bolder;">Urgent Report</p>
            <p id="details">Download and take to the nearest hospital/medical center .</p>
            </div>

            <div class="option-img" @click="downloadpatient()">
            <img  src="../assets/icon/downloadbold.svg" alt="" srcset="">  

            </div>
            </div>
            </div>

            <div ref="patient" style="display:none">
                      <urgentReport/>
                    </div>



                        </div>
                       
    
                
                            <div class="third-main">
                                        <div class="symptom-button" >
                                            <button  id="previous"   @click="previousButton">
                                            
                                            <img src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-left.svg" alt="" srcset="">   </button>
                                          
<!--                                            
                                            <button id="next" v-if="(showMain <= 6)" @click="nextButton" >
                                            
                                              <img src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  
                                            </button> -->
                                          </div>
                          </div>
    
    
              </div>
                  
     </div>
       
    
    
    
    
    
    </template>
    
    
    <script>
    
import urgentReport from '../components/urgent.vue'
import html2pdf from 'html2pdf.js'
    
  
       
    
    export default {
    
    name: 'urgent-component',
    components:{
        urgentReport
    
    },
    data(){
          return{
            title: null,

          }
    },
    methods:{

downloadpatient(){
  this.$refs.patient.style.display = "block";


html2pdf(this.$refs.patient, {
filename: `Rehabify Medical Report`,
margin: 0,
image: {type: 'jpeg', quality: .98},
jsPDF: {unit: 'mm', format : 'a4', orientation: 'portrait', putOnlyUsedFonts: true},
html2canvas: { dpi: 192, letterRendering: true, scale: 2 },

})

setTimeout((()=>{
 this.$refs.patient.style.display = "none";
}), -100000)

}

,

previousButton(){
  this.$router.go(-1)
}
    }
    
    
    
    
    }
    
    </script>
    
    
    <style scoped>
    
    #urgent {
        margin: 20px;
        font-size: 1rem;
        line-height: 30px;
        letter-spacing: .3px;
        text-align: center;
        padding-top: 30px;
    }
    #urgent-page .option{
            
            width: 80%;
            margin: auto;
            margin-top: 20px;
            color: var(--colorA);
    }
    
    
    
    
    </style>