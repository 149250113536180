<template>

  <div class="about">



    <div class="report">
             <div class="title">
                <Logo />
               <h2> REHABIFY CASE REPORT -PROFESSIONAL COPY

               </h2>


             </div>

           <div class="report-details">
             <section id="patient">
             <h4>PATIENT INFORMATION</h4>
             
             <div class="patient-details">
                <div class="container">
                   <div class="each">
                      <b>Name</b>
                      <p>{{fullname}}</p>

                   </div>
                   <div class="each">
                      <b>Age</b>
                      <p>{{age}}</p>

                   </div>
                   <div class="each">
                      <b>Gender</b>
                      <p>{{gender}}</p>

                   </div>
                   <div class="each">
                      <b>Email</b>
                      <p>{{ email }}</p>

                   </div>
                </div>

             </div>

            </section>


            <section id="clinical">
             <h4>ASSESSMENT SUMMARY</h4>
             
             <div class="patient-details">
                <div class="container">
                  <div class="each">
                   <p id="impression">Clinical Impression: <span style="font-size: .85rem;">{{ finaldiagnosis }}</span> </p>
                 
                   <p style="margin: 10px 0;">Percentage accuracy <span style="font-size: .85rem">{{finalpercentage}}%</span></p>
              
                  </div>

                  <div class="each">
                     <p id="impression">Symptoms suggesting the above impression from your assessment</p> 

                     <div v-for="(each, index) of currentDiagnosis" :key="index" style="font-size: .85rem">
                        <p>{{ index + 1 }}. {{ each }}</p>
                     </div>
                  </div>

                  <div class="each">
                    <p id="impression">Differentials: <span style="font-size: .85rem"> coming soon</span> </p>
                  </div>

               
                  <div class="each">
                   <p id="impression"  v-if="redFlag" style="color:red">Red Flags: <span>
                  
                     <div  v-for="(each, index) of redFlag" :key="index" style="font-size: .85rem; color: black;">
                        <p>{{ index + 1 }}. {{ each }}</p>
                     </div>

                  </span> </p>
                  <p id="impression"  v-else >Red Flags: <span style="font-size: .85rem"> None was reported
               </span> </p>
                 
                  
                  </div>

                  <div class="each">
                   <p id="impression" >Recommended special tests: <span style="font-size: .85rem">coming soon </span></p>
                  
                  </div>

                
                 

                  
                </div>
             </div>

            </section>

           </div>
    </div>


  </div>

</template>


<script>
import Logo from '../components/logo'
export default {

name: 'about-component' ,
components:{
Logo

},
props:{
   User: Object,
   Diagnosis: Array,
   Symptoms: Object

},
data(){
   return{
      user: this.User,
      diagnosis: this.Diagnosis,
   currentDiagnosis: null,
   fullname: null,
   age:null,
   email: null,
   gender: null,
   redFlag: [],
   finalpercentage:null,
   finaldiagnosis: null
   }
},
mounted(){
 
  
let foundUser =  localStorage.getItem('User')?  JSON.parse(localStorage.getItem('User')): {}
let founddiagnosis =  localStorage.getItem('Diagnosed_Condition')?  JSON.parse(localStorage.getItem('Diagnosed_Condition')): {}
    
  if (foundUser){
    this.fullname = foundUser.fullname
    this.age = foundUser.DOB
    this.email= foundUser.email
    this.gender = foundUser.gender
   
   } 

   if(founddiagnosis){
      this.finaldiagnosis = founddiagnosis.diagnosis 
      this.finalpercentage = founddiagnosis.percentage
   }
  
  
    let foundSymptoms =  localStorage.getItem('Symptoms')?  JSON.parse(localStorage.getItem('Symptoms')): null
    this.currentDiagnosis= foundSymptoms

    let Red =  localStorage.getItem('Red')?  JSON.parse(localStorage.getItem('Red')): null
    this.redFlag= Red
  
  


}


}
</script>


<style scoped>

.report{

height: 1100px;
width: 100%;
max-width: 700px;

margin: auto;
background-color: white;
border: 2px solid var(--colorB);
}

.report-details{
width: 95%;
margin: auto;
height: 90%;
padding: 10px;
display: flex;
flex-direction: column;
justify-content: space-between;



}
.report .title{
height: 8%;
background-color: #103d8ca5;

text-align: center;
display: flex;
align-items: center;
justify-content: center;
color: var(--colorB);
font-size: .8rem;
color: white;
margin-bottom: 20px;
}

.patient-details{
height: 90%;

width: 100%;
padding: 10px;
background-color: var(--colorAdim);
background-color:  #ee9cd936;
/* --colorBdim: #3879e9b5;
;; */
}

section h4 {
color: var(--colorB);
margin-bottom: 10px;
margin-left: 10px;
letter-spacing: .2px;

}


section#patient{
height: 20%;
}
section#clinical{
height: 75%;
}

#patient .container{
height: 100%;

display: grid;
grid-template: 1fr 1fr/ 1fr 1fr;

}
.patient-details .container .each{
 text-align: left;

}

#patient .each p{
margin-top: 12px;
}

#clinical .container{
height: 80%;

display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
text-align: center;

}
p#impression{
font-weight: 700;
font-size: 1rem;
}
p#impression span{
font-weight: 600;
color: var(--colorB);
font-size: 1rem;
}

p b {
 
font-weight: 700;
font-size: 1rem;

}


</style>