<template >

<div class="success-signup" v-show="showSuccess">
   <p> Successful Sign up! Check {{User.Email}} to verify your account
    <!-- You will be directed to login in <span>{{ counter}}</span> -->
</p>
    </div>
    <div class="signup-error" v-show="showError">
   <p>{{ signupErrorMessage }}</p>
    </div>


    <div :class=" this.showSuccess? 'signup success': 'signup'">
 
<div class="gateway">
    <div class="image-dis">
   

   <p class="details">Your <br> <span>Rehabilitation</span> Journey Starts <br> Here</p>
  <div class="img">
   <Imagedisplay/>
  </div>

</div>
<div class="gateway-dis">
    
    <div class="signup-gateway" v-show="showGateway">

<div class="gateway-title">
                <p>
                                Please choose from the options below 
                        </p>
</div>
<div class="gateway-options">


<div class="option">
<div class="option-title">
<p id="title">General SignUp</p>
<p id="details">This account is for clients/patients only</p>
</div>

<div class="option-img" @click="sigupClicked">
<img  id="arrowimg" src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  

</div>

</div>

<div class="option">
<div class="option-title">
<p id="title">Professional SignUp</p>
<p id="details">This account is for licensed Physiotherapist</p>
</div>

<div class="option-img" @click="sigupClicked() ; showReference()" >
<img id="arrowimg" src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  

</div>

</div>
</div>



</div>


<div class="form" >

  

<div id="form"  v-show="showForm">
<div class="form-content">
<h1>SIGN UP FORM</h1>

<form @submit.prevent="checkError" @reset="showGate">

<div class="firstname">
            <label for="firstname">First Name
                <span style="color: red !important; display: inline; float: none;">*</span>
            </label>
            <input type="text" :class="(this.FirstNameError? 'active' : '' )" ref="FirstName" v-model=" User.FirstName"  autocomplete="cc-given-name" autocapitalize="characters" id="firstname">
            <span v-show="FirstNameError">{{errorMessage.FirstName}}</span>
    </div>

<div class="lastname">
          <label for="lastname">Last Name
            <span style="color: red !important; display: inline; float: none;">*</span>
        </label>
        <input type="text" :class="(this.LastNameError? 'active' : '' )" ref="LastName" v-model=" User.LastName"  autocomplete="cc-family-name" id="lastname">
        <span v-show="LastNameError">{{errorMessage.LastName}}</span>
    </div>

<div class="password">
          <label for="password">Password
            <span style="color: red !important; display: inline; float: none;">*</span>
        </label>
        <input type="password" placeholder="6+ Characters" :class="(this.PasswordError? 'active' : '' )" ref="password"  v-model=" User.Password"   id="password">
        <span v-show="PasswordError">{{errorMessage.Password}}</span>
       
    </div>
    <p id="show"  @click="revealPassword()">{{passwordReveal? 'Hide': 'Show'}} password</p>


<!-- <div class="confirm">
          <label for="confirm-password">Confirm Password
            <span style="color: red !important; display: inline; float: none;">*</span>
        </label>
        <input type="password" :class="(this.ConfirmError? 'active' : '' )" ref="Confirm" v-model="User.Confirm"  autocomplete="" id="confirm-password">
        <span v-show="ConfirmError">{{errorMessage.Confirm}}</span>
       
    </div> -->

    <div class="gender">
        <label for="gender">Gender
            <span style="color: red !important; display: inline; float: none;">*</span>
        </label>
        <select v-model="User.Gender"  :class="(this.GenderError? 'active' : '' )" ref="Gender"  name="" id="gender" >
            <option value="">Please select</option>
            <option value="male">Male</option>
            <option value="male">Female</option>
        </select>
        <span v-show="GenderError">{{errorMessage.Gender}}</span>
</div>

<div class="DOB">
        <label for="birth">DOB
            <span style="color: red !important; display: inline; float: none;">*</span>
        </label>
        <input v-model="User.DOB" :class="(this.DOBError? 'active' : '' )" type="date" ref="DOB" autocomplete="bday" id="birth">
        <span v-show="DOBError">{{errorMessage.DOB}}</span>
</div>


<div class="email">
        <label for="email">Email
            <span style="color: red !important; display: inline; float: none;">*</span>
        </label>
       
        <input  autocomplete="on" :class="(this.EmailError? 'active' : '' )" ref="Email" type="email"  v-model=" User.Email" id="email">
        <span v-show="EmailError">{{errorMessage.Email}}</span>
</div>


<div class="phone">
        <label for="phone">Phone number
            <span style="color: red !important; display: inline; float: none;">*</span>
        </label>
        <input type="tel" autocomplete="on" placeholder="+ Country code" ref="Phone"  :class="(this.PhoneError? 'active' : '' )" v-model=" User.Phone"   id="phone">
        <span v-show="PhoneError">{{errorMessage.Phone}}</span>
    </div>


<div class="qualification" v-show="physioSignup">
    <label for="birth">Physiotherapy Qualification
            <span style="color: red !important; display: inline; float: none;">*</span>
        </label>
    <select v-model="User.Qualification" :class="(this.QualificationError? 'active' : '' )" ref="Qualification" name="" id="gender" >
            <option value="">Please select</option>
            <option value="male">Bachelor</option>
            <option value="male">Master</option>
            <option value="male">Doctor</option>
        </select>
        <span v-show="QualificationError">{{errorMessage.Qualification}}</span>
</div>

<div class="practice" v-show="physioSignup" >
        <label for="country">Country of Practice
        <span style="color: red !important; display: inline; float: none;">*</span>      
     </label>

        <select id="country" autocomplete="off" ref="Country" :class="(this.CountryError? 'active' : '' )" v-model="User.Country" name="country" >
            
            <option value="Afghanistan">Afghanistan</option>
            <option value="Åland Islands">Åland Islands</option>
            <option value="Albania">Albania</option>
            <option value="Algeria">Algeria</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Andorra">Andorra</option>
            <option value="Angola">Angola</option>
            <option value="Anguilla">Anguilla</option>
            <option value="Antarctica">Antarctica</option>
            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Aruba">Aruba</option>
            <option value="Australia">Australia</option>
            <option value="Austria">Austria</option>
            <option value="Azerbaijan">Azerbaijan</option>
            <option value="Bahamas">Bahamas</option>
            <option value="Bahrain">Bahrain</option>
            <option value="Bangladesh">Bangladesh</option>
            <option value="Barbados">Barbados</option>
            <option value="Belarus">Belarus</option>
            <option value="Belgium">Belgium</option>
            <option value="Belize">Belize</option>
            <option value="Benin">Benin</option>
            <option value="Bermuda">Bermuda</option>
            <option value="Bhutan">Bhutan</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
            <option value="Botswana">Botswana</option>
            <option value="Bouvet Island">Bouvet Island</option>
            <option value="Brazil">Brazil</option>
            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
            <option value="Brunei Darussalam">Brunei Darussalam</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Burkina Faso">Burkina Faso</option>
            <option value="Burundi">Burundi</option>
            <option value="Cambodia">Cambodia</option>
            <option value="Cameroon">Cameroon</option>
            <option value="Canada">Canada</option>
            <option value="Cape Verde">Cape Verde</option>
            <option value="Cayman Islands">Cayman Islands</option>
            <option value="Central African Republic">Central African Republic</option>
            <option value="Chad">Chad</option>
            <option value="Chile">Chile</option>
            <option value="China">China</option>
            <option value="Christmas Island">Christmas Island</option>
            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
            <option value="Colombia">Colombia</option>
            <option value="Comoros">Comoros</option>
            <option value="Congo">Congo</option>
            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
            <option value="Cook Islands">Cook Islands</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Cote D'ivoire">Cote D'ivoire</option>
            <option value="Croatia">Croatia</option>
            <option value="Cuba">Cuba</option>
            <option value="Cyprus">Cyprus</option>
            <option value="Czech Republic">Czech Republic</option>
            <option value="Denmark">Denmark</option>
            <option value="Djibouti">Djibouti</option>
            <option value="Dominica">Dominica</option>
            <option value="Dominican Republic">Dominican Republic</option>
            <option value="Ecuador">Ecuador</option>
            <option value="Egypt">Egypt</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Equatorial Guinea">Equatorial Guinea</option>
            <option value="Eritrea">Eritrea</option>
            <option value="Estonia">Estonia</option>
            <option value="Ethiopia">Ethiopia</option>
            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
            <option value="Faroe Islands">Faroe Islands</option>
            <option value="Fiji">Fiji</option>
            <option value="Finland">Finland</option>
            <option value="France">France</option>
            <option value="French Guiana">French Guiana</option>
            <option value="French Polynesia">French Polynesia</option>
            <option value="French Southern Territories">French Southern Territories</option>
            <option value="Gabon">Gabon</option>
            <option value="Gambia">Gambia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Germany</option>
            <option value="Ghana">Ghana</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Greece">Greece</option>
            <option value="Greenland">Greenland</option>
            <option value="Grenada">Grenada</option>
            <option value="Guadeloupe">Guadeloupe</option>
            <option value="Guam">Guam</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Guernsey">Guernsey</option>
            <option value="Guinea">Guinea</option>
            <option value="Guinea-bissau">Guinea-bissau</option>
            <option value="Guyana">Guyana</option>
            <option value="Haiti">Haiti</option>
            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
            <option value="Honduras">Honduras</option>
            <option value="Hong Kong">Hong Kong</option>
            <option value="Hungary">Hungary</option>
            <option value="Iceland">Iceland</option>
            <option value="India">India</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
            <option value="Iraq">Iraq</option>
            <option value="Ireland">Ireland</option>
            <option value="Isle of Man">Isle of Man</option>
            <option value="Israel">Israel</option>
            <option value="Italy">Italy</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Japan">Japan</option>
            <option value="Jersey">Jersey</option>
            <option value="Jordan">Jordan</option>
            <option value="Kazakhstan">Kazakhstan</option>
            <option value="Kenya">Kenya</option>
            <option value="Kiribati">Kiribati</option>
            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
            <option value="Korea, Republic of">Korea, Republic of</option>
            <option value="Kuwait">Kuwait</option>
            <option value="Kyrgyzstan">Kyrgyzstan</option>
            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
            <option value="Latvia">Latvia</option>
            <option value="Lebanon">Lebanon</option>
            <option value="Lesotho">Lesotho</option>
            <option value="Liberia">Liberia</option>
            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
            <option value="Liechtenstein">Liechtenstein</option>
            <option value="Lithuania">Lithuania</option>
            <option value="Luxembourg">Luxembourg</option>
            <option value="Macao">Macao</option>
            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
            <option value="Madagascar">Madagascar</option>
            <option value="Malawi">Malawi</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Maldives">Maldives</option>
            <option value="Mali">Mali</option>
            <option value="Malta">Malta</option>
            <option value="Marshall Islands">Marshall Islands</option>
            <option value="Martinique">Martinique</option>
            <option value="Mauritania">Mauritania</option>
            <option value="Mauritius">Mauritius</option>
            <option value="Mayotte">Mayotte</option>
            <option value="Mexico">Mexico</option>
            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
            <option value="Moldova, Republic of">Moldova, Republic of</option>
            <option value="Monaco">Monaco</option>
            <option value="Mongolia">Mongolia</option>
            <option value="Montenegro">Montenegro</option>
            <option value="Montserrat">Montserrat</option>
            <option value="Morocco">Morocco</option>
            <option value="Mozambique">Mozambique</option>
            <option value="Myanmar">Myanmar</option>
            <option value="Namibia">Namibia</option>
            <option value="Nauru">Nauru</option>
            <option value="Nepal">Nepal</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Netherlands Antilles">Netherlands Antilles</option>
            <option value="New Caledonia">New Caledonia</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Niger">Niger</option>
            <option value="Nigeria">Nigeria</option>
            <option value="Niue">Niue</option>
            <option value="Norfolk Island">Norfolk Island</option>
            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
            <option value="Norway">Norway</option>
            <option value="Oman">Oman</option>
            <option value="Pakistan">Pakistan</option>
            <option value="Palau">Palau</option>
            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
            <option value="Panama">Panama</option>
            <option value="Papua New Guinea">Papua New Guinea</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Peru</option>
            <option value="Philippines">Philippines</option>
            <option value="Pitcairn">Pitcairn</option>
            <option value="Poland">Poland</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Qatar">Qatar</option>
            <option value="Reunion">Reunion</option>
            <option value="Romania">Romania</option>
            <option value="Russian Federation">Russian Federation</option>
            <option value="Rwanda">Rwanda</option>
            <option value="Saint Helena">Saint Helena</option>
            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
            <option value="Saint Lucia">Saint Lucia</option>
            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
            <option value="Samoa">Samoa</option>
            <option value="San Marino">San Marino</option>
            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Senegal">Senegal</option>
            <option value="Serbia">Serbia</option>
            <option value="Seychelles">Seychelles</option>
            <option value="Sierra Leone">Sierra Leone</option>
            <option value="Singapore">Singapore</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Solomon Islands">Solomon Islands</option>
            <option value="Somalia">Somalia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
            <option value="Spain">Spain</option>
            <option value="Sri Lanka">Sri Lanka</option>
            <option value="Sudan">Sudan</option>
            <option value="Suriname">Suriname</option>
            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
            <option value="Swaziland">Swaziland</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
            <option value="Taiwan">Taiwan</option>
            <option value="Tajikistan">Tajikistan</option>
            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
            <option value="Thailand">Thailand</option>
            <option value="Timor-leste">Timor-leste</option>
            <option value="Togo">Togo</option>
            <option value="Tokelau">Tokelau</option>
            <option value="Tonga">Tonga</option>
            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
            <option value="Tunisia">Tunisia</option>
            <option value="Turkey">Turkey</option>
            <option value="Turkmenistan">Turkmenistan</option>
            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
            <option value="Tuvalu">Tuvalu</option>
            <option value="Uganda">Uganda</option>
            <option value="Ukraine">Ukraine</option>
            <option value="United Arab Emirates">United Arab Emirates</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="United States">United States</option>
            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Uzbekistan">Uzbekistan</option>
            <option value="Vanuatu">Vanuatu</option>
            <option value="Venezuela">Venezuela</option>
            <option value="Viet Nam">Viet Nam</option>
            <option value="Virgin Islands, British">Virgin Islands, British</option>
            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
            <option value="Wallis and Futuna">Wallis and Futuna</option>
            <option value="Western Sahara">Western Sahara</option>
            <option value="Yemen">Yemen</option>
            <option value="Zambia">Zambia</option>
            <option value="Zimbabwe">Zimbabwe</option>
        </select>

        <span v-show="CountryError">{{errorMessage.Country}}</span>
</div>

<div class="Address" v-show="physioSignup">
        <label for="language"> Practice Address           
        </label>
        <input type='text'  v-model=" User.Address" id="language" >
      
</div>

<!-- <div class="GPemail" v-show="!physioSignup">
        <label for="GP">GP/Doctor Email
        </label>
        <input type="email"  v-model=" User.GPEmail" >
       
</div> -->

<div class="language">
        <label for="language">Preferred Language for Virtual therapy sessions
            
        </label>
        <input type='text' placeholder="All separated with a comma (,)"  v-model=" User.Language" id="language" >
      
</div>



<div class="about" v-show="physioSignup">
    
        <label for="about"> Skills and Specialty
            <span style="color: red !important; display: inline; float: none;">{{wordlength}} words</span>
        </label>
        <textarea type=""  rows="5" cols="50" maxlength="500" @input="wordCount" v-model=" User.About"></textarea>  </div>


 <div class="platform" v-show="!physioSignup">
        <p>Preferred  Platform For Vitual Treatment (Select all that apply)</p>
             <div class="opt">
                <input v-model="User.platform" type="checkbox" value="Whatsapp" id="whatsapp" >
                 <label for="whatsapp" >Whatsapp</label>
             </div>
             <div class="opt">
                <input v-model="User.platform" type="checkbox" value=Skype id="skype">
                 <label for="skype">Skype</label>
             </div>
             <div class="opt">
                <input v-model="User.platform" type="checkbox" value="google meet" id="gogglemeet">
                 <label for="gogglemeet">Google Meet</label>
             </div>
             <div class="opt">
                <input v-model="User.platform" type="checkbox" value="not sure" id="notsure">
                 <label for="notsure">Not Sure</label>
             </div>
        
 </div>


 <div class="platform" v-show="physioSignup">
        <p>I want patients to contact me through (select all that apply)</p>
             <div class="opt">
                <input v-model="User.PhysioPlatform" value="Rehabify" type="checkbox" id="rehabify" >
                 <label for="rehabify" >Rehabify Chat</label>
             </div>
             <div class="opt">
                <input v-model="userPhysioPlatform" value="Email" type="checkbox" id="email-paltform">
                 <label for="email-platform">Email</label>
             </div>
             <div class="opt">
                <input v-model="User.PhysioPlatform" value="phone" type="checkbox" id="number">
                 <label for="number">Phone Number</label>
             </div>
             <div class="opt">
                <input v-model="User.PhysioPlatform" value="any" type="checkbox" id="any">
                 <label for="any">Any platform</label>
             </div>
           
            
 </div>


<div class="TC">

    <input v-model="User.TC" type="checkbox" id="terms">
        <label for="terms"><a href="http://physiopedia.com"  target="_blank">Accept our Terms and Conditions</a></label>  
      
    </div>
    <span id="TCerror" v-show="TCError">{{errorMessage.TC}}</span>



<div id="buttons">
            <button type="reset" @click="showGate">
                    <div class="option-img" >
                            <img src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-left.svg" alt="" srcset="">  
                        </div>
    
            </button>
            <button type="submit" id="next">
                    <div class="option-img"  >
                            <img src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  
                        </div>
    
            </button>


            
 </div>
</form>





</div>


</div>


</div>
</div>
</div>

    </div>
 
 </template>
 
 
 
     <script>

import Imagedisplay from '../components/Imagedisplay.vue';
import {mapActions} from 'vuex'
import {auth, db, createUserWithEmailAndPassword, sendEmailVerification, doc, setDoc } from '@/main.js'




    
     export default {
     
     name: 'login-component' ,
     components:{
       Imagedisplay
     },
     data(){
       return{
        showSuccess:false,
        showError: false,
        signupErrorMessage: '',
        counter:5,
        User:{

                    PhysioPlatform: [],
                    TC : false,
                    Language : null,
                    Email: null,
                    Phone: null,
                    Country: null,
                    Qualification: null,
                    Gender: null,
                    DOB: null,
                    FirstName: null,
                    LastName: null,
                    platform: [],
                    Password: null,
                    About: null,
                    Address: null
                           
                },

        

                    EmailError: false,
                    LanguageError: false,
                    CountryError: false,
                    PlatformError: false,
                    PhoneError: false,
                    QualificationError: false,
                    DOBError: false,
                    FirstNameError: false,
                    LastNameError: false,
                    PasswordError: false,
                    TCError: false,
                    PhysioPlatform: false,
                    ConfirmError: false,
                    GenderError: false,
                    
                    

        
          
          showPassword: false,
          errorMessage: {
                    TC : null,
                    Email: null,
                    Phone: null,
                    Country: null,
                    Qualification: null,
                    Gender: null,
                    DOB: null,
                    FirstName: null,
                    LastName: null,
                    platform: null,
                    Password: null,
                    Confirm:null,
                    PhysioPlatform: null
                   
            

          } ,
         
          physioSignup: false,
          showGateway: true,
          showForm: false,
          passwordReveal: false,
          wordlength: 100
       }
     },
     beforeRouteLeave(){
    this.showGateway= true
        this.optionSelected= false 
        this.physioSignup = false
    
 },
     methods: {
        ...mapActions(['getPatient', 'getPhysio']),

        revealPassword(){
      this.passwordReveal= !this.passwordReveal
       this.$refs.password.type = this.passwordReveal?  'text': 'password'
   },
       

        sigupClicked(){
            this.showForm = true; 
            this.showGateway= false
        },

        showReference(){

            this.physioSignup = true
        },

        // this sends data to VUes- checks if it's a patient or a Physio
    saveData(){

            //format age
            let today = new Date ().getFullYear()
            let myDOB = new Date (this.User.DOB).getFullYear()
            let age= (today - myDOB)

            let allData= {}
                if(this.physioSignup){
                allData= {...this.User, 
                    Age:age,
                     Category: 'Physiotherapist',
                      fullname : `${this.User.LastName} ${this.User.FirstName}`,
                      createdAt: `${new Date().getDate()}, ${new Date().getMonth()}, ${new Date().getFullYear()} `,
                      subscribed: false,
                      verified: false,
                      totalPatient: 0,
                      totalRating: 0,
                      finalRating: Math.ceil(this.totalRating/this.totalPatient)

                    }  
                }
            else{
                 allData= {
                    ...this.User, 
                    Age:age, 
                    Category: 'Patient',
                    createdAt: `${new Date().getUTCDate()}/${new Date().getUTCMonth() + 1}/${new Date().getUTCFullYear()} `,
                     subscribed: false,
                      diagnosis: [],
                      exercise: []
                 }
               
             }

          
            
            //firebase signup 
              createUserWithEmailAndPassword(auth, allData.Email, allData.Password)
                .then((result)=>{
                    let id = result.user.uid
                  

                  sendEmailVerification(auth.currentUser)
                  .then(()=>{
                    alert('sent')
                  })

                 
                    let myRef = doc(db, 'Profile', id)
                     setDoc(myRef, allData)
                            
                    // // if signed up, send the data to the newuser model
                    //     if(this.physioSignup){
                
                    //        this.getPhysio(allData)
                 
                    //         }
                    //     else{
                            
                    //         this.getPatient(allData)
                        
                    //     }
                        // display success message
                    this.showSuccess = true
                        //countdown and take the user to the login page
                    let displayCounter= setInterval(()=>{
                            
                                this.counter = this.counter - 1

                            },1000);
                            
                            setTimeout(()=>{
                                clearInterval(displayCounter)
                                this.$router.replace({

                                path: `/login`
                                })
                            
                            },5000)
                })
                .catch((err)=>{ 
                  
                   
                    let errcode = err.code

                    // display error message 
                    let displayError= setInterval(()=>{
                              
                        this.showError= true
                    if (errcode === 'auth/email-already-in-use'){
                        this.signupErrorMessage = 'Email is already in use'
                    }
                   
                    if(errcode === 'auth/invalid-password' || errcode === 'auth/weak-password' ){
                        this.signupErrorMessage = 'Password is too weak- must at least six characters'
                    }
                    else{
                        this.signupErrorMessage = `${errcode}`
                    }
                        },1000);
                        
                        setTimeout(()=>{
                            clearInterval(displayError)
                            this.showError= false
                            this.signupErrorMessage = ''
                        
                        },4000) 
                
                    }) 

        


        },
     
       wordCount(){
     
        let aboutMe = this.User.About.length
       
        this.wordlength = 100 -aboutMe
        

       }, 

       showGate(e){
        this.showGateway= true
        this.showForm = false
         let form= e.target
         form.reset()
        // e.target.type= 'reset'
      },

    checkError(e){
        e.preventDefault()
        let error =[]
        let patient= ['FirstName', 'Email', 'Password', 'Phone','LastName', 'DOB', 'Gender', 'TC']
   let physio = ['FirstName', 'Email', 'Password', 'Country', 'Phone', 'Qualification','LastName', 'DOB', 'Gender', 'TC'];

          
 for(var mydata in this.User){
             
         if (!this.User[mydata]) {
                        error.push(mydata)
                    this[`${mydata}Error`]= true;
                        if(mydata === 'TC'){
                        this.errorMessage[`${mydata}`] = `Kindly accept out terms and conditons`
                    }
                    else{
                        this.errorMessage[`${mydata}`] = 'Required answer'
                    }
                
                 }


         else{
                    error.filter(err => err !== mydata)
                this[`${mydata}Error`]= false;
                    this.errorMessage[`${mydata}`] =''
                }

     }
    

            
         if (this.physioSignup && error.every(err => !physio.includes(err))){


            this.saveData()

}

if (!this.physioSignup && error.every(err => !patient.includes(err))){


             this.saveData()
                   
                
        }
       }
    
     }
     
    }
     
     
    
     </script>
     
     
     <style scoped>
     
    
     </style>