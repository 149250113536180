<template>

   <div class="about">
 
 
 
     <div class="report">
              <div class="title">
                 <Logo />
                <h2> REHABIFY CASE REPORT- PATIENT COPY
 
                </h2>
 
 
              </div>
 
            <div class="report-details">
              <section id="patient">
              <h4>PATIENT INFORMATION</h4>
              
              <div class="patient-details">
                 <div class="container">
                    <div class="each">
                       <b>Name</b>
                       <p>{{fullname}}</p>
 
                    </div>
                    <div class="each">
                       <b>Age</b>
                       <p>{{age}}</p>
 
                    </div>
                    <div class="each">
                       <b>Gender</b>
                       <p>{{gender}}</p>
 
                    </div>
                    <div class="each">
                       <b>Email</b>
                       <p>{{ email }}</p>
 
                    </div>
                 </div>
 
              </div>
 
             </section>
 
 
             <section id="clinical">
              <h4>ASSESSMENT SUMMARY</h4>
              
              <div class="patient-details">
                 <div class="container">
                   <div class="each">
                    <p id="impression">Clinical Impression: <span style="font-size: .85rem;">{{ finaldiagnosis }}</span> </p>
                   
                    <p>Percentage accuracy <span>{{finalpercentage}}%</span></p>
                   
                     <p>

                        For an accurate diagnosis of your symptoms, it is recommended to consult a physiotherapist or a doctor, as your symptoms may not fit into a single diagnosis. 
                   </p>
                   </div>
                   
                   <div class="each">
                     <p id="impression">Symptoms suggesting the above impression from your assessment</p> 
                         <div v-for="(each, index) of currentDiagnosis" :key="index">
                            <p>{{ index + 1 }}. {{ each }}</p>
                         </div>
                                           
                   </div>
 
                  <div>
                   <div class="each">
                    <!-- <p id="impression">It could also be: <span  style="font-size: .85rem;">Infrapatella bursitis, Plica</span> </p> -->
                    <p id="impression">Next step : <span  style="font-size: .85rem;">You can share this report with a 
                     Physiotherapist/Doctor for further assessment and treatment</span> </p>
                   
                  </div>
                  </div>
 
                   
                 </div>
              </div>
 
             </section>
 
            </div>
     </div>
 
 
   </div>
 
 </template>
 
 
 <script>
 import Logo from '../components/logo'
 export default {
 
 name: 'about-component' ,
 components:{
 Logo
 
 },
 props:{
    User: Object,
    Diagnosis: Array,
    Symptoms: Object
 
 },
 data(){
    return{
       user: this.User,
       diagnosis: this.Diagnosis,
    currentDiagnosis: null,
    fullname: null,
    age:null,
    email: null,
    gender: null,
    finaldiagnosis: null,
   finalpercentage:null
    }
 },
 
 mounted(){
  
 let foundUser =  localStorage.getItem('User')?  JSON.parse(localStorage.getItem('User')): {}
   
 let founddiagnosis =  localStorage.getItem('Diagnosed_Condition')?  JSON.parse(localStorage.getItem('Diagnosed_Condition')): {}
    
    if (foundUser){
      this.fullname = foundUser.fullname
      this.age = foundUser.DOB
      this.email= foundUser.email
      this.gender = foundUser.gender
     
     } 
  
     if(founddiagnosis){
        this.finaldiagnosis = founddiagnosis.diagnosis 
        this.finalpercentage = founddiagnosis.percentage
     }
 
   let foundSymptoms =  localStorage.getItem('Symptoms')?  JSON.parse(localStorage.getItem('Symptoms')): null
   this.currentDiagnosis= foundSymptoms
 
 
 }
 
 
 }
 </script>
 
 
 <style scoped>
 
 
.report{

height: 1100px;
width: 100%;
max-width: 700px;

margin: auto;
background-color: white;
border: 2px solid var(--colorB);
}

.report-details{
width: 95%;
margin: auto;
height: 90%;
padding: 10px;
display: flex;
flex-direction: column;
justify-content: space-between;



}
.report .title{
height: 8%;
background-color: #103d8ca5;

text-align: center;
display: flex;
align-items: center;
justify-content: center;
color: var(--colorB);
font-size: .8rem;
color: white;
margin-bottom: 20px;
}

.patient-details{
height: 90%;

width: 100%;
padding: 10px;
background-color: var(--colorAdim);
background-color:  #ee9cd936;
/* --colorBdim: #3879e9b5;
;; */
}

section h4 {
color: var(--colorB);
margin-bottom: 10px;
margin-left: 10px;
letter-spacing: .2px;

}


section#patient{
height: 20%;
}
section#clinical{
height: 75%;
}

#patient .container{
height: 100%;

display: grid;
grid-template: 1fr 1fr/ 1fr 1fr;

}
.patient-details .container .each{
 text-align: left;

}

#patient .each p{
margin-top: 12px;
}

#clinical .container{
height: 80%;

display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
text-align: center;

}
p#impression{
font-weight: 700;
font-size: 1rem;
}
p#impression span{
font-weight: 600;
color: var(--colorB);
font-size: 1rem;
}

p b {
 
font-weight: 700;
font-size: 1rem;

}

  
 
 
 </style>