<template>

    <div class="home">

      
 
      <p>Official launch date - April 2024</p>
 
 <Images/>
 
 
 
    </div>
 
 </template>
 
 
 <script>
 import Images from "../components/Imagedisplay.vue";
 
 export default {
 
 name: 'privacy-component' ,
 components:{
   Images
 }
 
 
 }
 </script>
 
 
 <style scoped>
 
 
 
 </style>