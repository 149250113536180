<template>

   <div class="about">About page coming soon</div>

   <p>Official launch date - April 2024</p>
 
 </template>
 
 
 <script>
 
 export default {
 
 name: 'about-component' ,
 components:{
 
 
 }
 
 
 }
 </script>
 
 
 <style scoped>
 
 
 </style>