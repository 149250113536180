<template>
  <Header  /> 
 

  <div class="import">

    <router-view > </router-view>
  </div>


 
   
</template>

<script>



import Header from './components/header'
import AOS from 'aos'

export default {
  name: 'App',
  components: {
    Header,
    
    
  },

  data(){
    return{
     
    Auth: false
    }
  },

  methods:{
  
  },
  beforeCreate() {
		this.$store.commit('changeAuth');
	},
  mounted() {
    
      
    AOS.init({
      // Global settings:
     offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
      duration: 2000, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    })

  
  
    
  },
    

}


</script>


<style>



</style>
