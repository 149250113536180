let AllCondition = {

    'Shoulder' : [

    {
    name: 'Adhesive capsulitis',
    symptoms: ['Moderate to severe pain', 'Dull and achy pain', 'Pain not aggravated by palpation', 'Pain has been progressively worsening',   'Diffuse shoulder pain',   'Limited shoulder AROM in all directions',   'Overhead shoulder movement aggravate symptom',   'Shoulder joint feels restricted and stiff',   'Shoulder pain eased by rest',   'Difficulty with overhead movement',   'Diagnosed with diabetes']
    },
    
    {name: 'Rotator cuff syndrome',
    symptoms:  ['Moderate to severe pain',   'Dull and achy pain',   'Pain has been progressively worsening', 'Anterior shoulder pain',   'Diffuse shoulder pain',   'Limited AROM in all direction',   'Overhead movement aggravate symptom',   'Shoulder pain eased by rest',   'Difficulty with overhead movement',   'Engage in sports with overhead movement',   'Recently started working out',   'Over 50 years']
    },
    
    { name: 'Subacromial pain syndrome',
    
    symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Pain has been progressively worsening',   'Pain at the tip of the shoulder',   'Overhead shoulder movement aggravate symptom',   'Shoulder pain eased by rest',   'Shoulder pain is aggravated when laid upon',   'Difficulty with overhead movement',   'Engage in sports with overhead movement',   'Recently started working out']
    },
    
    { name: 'Shoulder bursitis', 
    symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Pain has been progressively worsening',   'Anterior shoulder pain',   'Limited shoulder AROM in all direction',   'Overhead shoulder movement aggravate symptom',   'Shoulder pain eased by rest',   'Shoulder pain is aggravated when laid upon',   'Difficulty with overhead movement',   'Engage in sports with overhead movement',   'Diagnosed with diabetes']
    },
    
    {
    name: 'Quadrilateral pain syndrome', 
    symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Pain has been progressively worsening',   'Posterior shoulder pain',   'Diffuse shoulder pain',   'Overhead movement aggravate symptom',   'Shoulder pain eased by rest',   'Difficulty with overhead movement',   'Engage in sports with overhead movement',   'Recently started working out']
    },
    
    
    {name: 'Calcific tendinopathy',
     symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Pain has been progressively worsening',   'Diffuse shoulder pain',   'Crepitus at the shoulder',   'Overhead movement aggravate symptom',   'Shoulder pain eased by rest',   'Difficulty with overhead movement',   'Diagnosed with diabetes']
    },
    
    { name: 'Thoracic outlet syndrome',
    symptoms: ['Minor to moderate pain',   'Dull and achy pain',   'Pain has been progressively worsening',   'Anterior shoulder pain',   'Diffuse shoulder pain',   'Limited shoulder AROM in all direction',   'Overhead shoulder movement aggravate symptom',   'Weaker arms',   'Shoulder feels unstable',   'Shoulder pain eased by rest',   'Shoulder feels unstable',   'Difficulty with overhead movement',   'Engage in sports with overhead movement',   'Recently started working out']
    },
    
    {name: 'Glenohumeral joint OA',
    symptoms: ['Moderate to severe pain',   'Pain has been progressively worsening',   'Posterior shoulder pain',   'Deep shoulder pain',   'Limited shoulder AROM in all direction',   'Crepitus at the shoulder',   'Overhead shoulder movement aggravate symptom',   'Shoulder joint feels restricted and stiff',   'Shoulder pain not eased by rest',   'Difficulty with overhead movement',  'Over 50 years']
    },
    
    
    {name: 'Dead arm syndrome',
     symptoms: ['Moderate to severe pain',   'Pain has been progressively worsening',   'Anterior shoulder pain', 'Deep shoulder pain',   'Overhead shoulder movement aggravate symptom',   'Shoulder pain eased by rest',   'Shoulder gives way',   'Shoulder feels unstable',   'Difficulty with overhead movement',   'Engage in sports with overhead movement']
     },
    
    {name: 'Hill sachs lesion',
    symptoms: ['Moderate to severe pain',   'Pain has been progressively worsening',   'Anterior shoulder pain',   'Deep shoulder pain',   'Limited shoulder AROM in all direction',   'Overhead shoulder movement aggravate symptom',   'Shoulder gives way',   'Shoulder feels unstable',   'Difficulty with overhead movement',   'Engage in sports with overhead movement']
    },
    
    {name: 'Acromioclavicular arthritis',
    symptoms: ['Moderate to severe pain',   'Pain has been progressively worsening',   'Pain at the tip of the shoulder',   'Anterior shoulder pain',   'Deep shoulder pain',   'Crepitus at the shoulder',   'Overhead shoulder movement aggravate symptom',   'Shoulder pain not eased by rest',   'Difficulty with overhead movement',   'Engage in sports with overhead movement',   'Recently started working out',   'Pain with weight bearing on arm']
    },
    
    { name: 'Bankart lesion',
    symptoms: ['Moderate to severe pain',   'Pain not aggravated by palpation',   'Pain has been progressively worsening',   'Anterior shoulder pain',   'Deep shoulder pain',   'Crepitus at the shoulder',   'Overhead shoulder movement aggravate symptom',   'Shoulder feels unstable',   'Difficulty with overhead movement',   'Engage in sports with overhead movement',   'Recently started working out']
    },
    
    
    { name: 'SLAP lesion',
     symptoms: ['Moderate to severe pain',   'Pain not aggravated by palpation',   'Pain has been progressively worsening',   'Pain at the tip of the shoulder',   'Deep shoulder pain',   'Crepitus at the shoulder', 'Overhead shoulder movement aggravate symptom',   'Shoulder pain is aggravated when laid upon',   'Difficulty with overhead movement',   'Engage in sports with overhead movement',   'Recently started working out']
    }
    ],


    'Wrist' : [

        {name: 'Carpal tunnel syndrome', symptoms: ['Minor to moderate pain',   'Pain has been progressively worsening',   'Chronic pain',   'Medial wrist pain',   'Bilateral wrist pain',   'Wrist pain aggravated by prolong writing',   'Wrist pain not relieved by rest',   'Early morning stiffness on affected wrist joint',   'Paresthesia on affected hand and fingers',   'Wrist pain started after a fall on affected hand',   'Swollen wrist' ]},
        
        {name: 'De Quervains tenosynovitis', symptoms: ['Lateral wrist pain',   'Pain at base of thumb',   'Tender wrist pain',   'Crepitus at the affected wrist joint',   'Paresthesia on affected hand and fingers',   'Wrist pain started after a fall on affected hand',   'Ganglion Cyst',   'Dull and achy pain',   'Tender wrist pain',   'Wrist pain aggravated by prolong writing',   'Paresthesia on affected hand and fingers',   'Wrist pain started after a fall on affected hand',   'Pain not relieved with medications',   'Swollen wrist']},
        
        {name: 'Guyon Canal Syndrome', symptoms: 
        ['Wrist pain started after a fall on affected wrist',   'Affected arm feels more weak than painful',   'Paresthesia on affected hand and fingers',   'Minor to moderate pain',   'Moderate to severe pain',   'Pain not relieved with medication',   'Wrist pain aggravated by weight bearing on affected arm',   'Tender wrist pain']},
        
         {name: 'Radial Tunnel Syndrome', symptoms: ['Wrist pain aggravated by lifting heavy objects',   'Paresthesia on affected hand and fingers',   'Tender wrist pain',   'Moderate to severe',   'Lateral wrist pain',   'Dull and achy pain']},
        
        
        {name: 'Scaphoid fracture', symptoms: 
        ['Moderate to severe pain', 'Dull and Achy pain', 'Pain has been progressively improving',   'Lateral wrist pain', 'Pain at base of thumb',   'Wrist pain aggravated when lifting heavy objects',   'Tender wrist joint',   'Limited multidirectional AROM at wrist joint', 'Wrist pain is relieved by rest', 'Wrist pain started after fall on affected hand',   'Wrist pain aggravated by weight bearing on affected arm',   'Swollen wrist']},
        
        {name: 'Scapholunate ligament injury', symptoms: ['Moderate to severe pain', 'Pain has been progressively worsening',   'Lateral wrist pain', 'Pain at base of thumb', 'Wrist pain aggravated when lifting heavy objects', 'Tender wrist joint',  'Limited multidirectional AROM at wrist joint', 'Crepitus at affected joint',   'Wrist pain is relieved by rest', 'Wrist joint feels unstable', 'Wrist pain started after a fall on affected hand', 'Wrist pain aggravated by weight bearing on affected hand', 'Swollen wrist']},
        
        {name: 'Carpometacarpal joint OA', symptoms: 
        ['Over 50 years','Moderate to severe pain', 'Pain has been progressively worsening', 'Middle wrist pain', 'Medial wrist pain', 'Lateral wrist pain', 'Pain at the base of the thumb', 'Wrist pain aggravated when lifting heavy objects', 'Tender wrist joint', 'Limited multidirectional AROM at wrist joint', 'Crepitus at affected wrist joint',   'Stiff and restricted wrist joint', 'Wrist pain not relieved by rest',   'Early morning stiffness on affected wrist joint',   'Wrist pain aggravated by weight bearing on affected hand',   'Swollen wrist']},
        
        {name: 'Scaphotrapeziotrapezoid joint OA',  symptoms: ['Moderate to severe pain', 'Pain has been progressively worsening',   'Lateral wrist pain', 'Pain at base of thumb', 'Wrist pain aggravated when lifting heavy objects',   'Tender wrist joint', 'Limited multidirectional AROM at wrist joint', 'Crepitus at affected wrist joint', 'Stiff and restricted wrist joint', 'Wrist pain not relieved by rest', 'Wrist pain aggravated by weight bearing on affected hand','Over 50 years']},
        
        {name: 'Lunate instability', symptoms: ['Moderate to severe pain', 'Pain has been progressively worsening', 'Lateral wrist pain', 'Pain at base of thumb', 'Wrist pain aggravated by lifting heavy objects', 'Tender wrist joint', 'Limited multidirectional AROM at wrist joint', 'Crepitus at affected wrist',   'Wrist pain is relieved by rest', 'Wrist joint feels unstable',   'Wrist joint started after a fall on affected hand', 'Wrist pain aggravated by weight bearing on affected hand', 'Swollen wrist']},
        
        {name: `Smith's fracture`, symptoms: 
        ['Moderate to severe pain', 'Pain has been progressively improving', 'Lateral wrist pain', 'Wrist pain aggravated when lifting heavy objects', 'Tender wrist joint', 'Limited multidirectional AROM at wrist joint', 'Wrist pain is relieved by rest', 'Wrist joint feels unstable', 'Wrist pain started after a fall on affected hand',   'Wrist pain aggravated by weight bearing on affected hand',   'Swollen wrist']}
            
    ],

    'Knee' : [ 

        {name: 'Ligament pathology (ACL)', symptoms: ['Moderate to severe pain ',   'Minor to moderate pain',   'Knee joint feels like it is giving away',   'Knee joint feels unstable',   'Knee pain aggravated by prolonged standing/ walking',   'Knee pain eased with rest',   'Knee pain aggravated by stair climbing',   'Pain has been progressively improving',   'Pain not aggravated by palpation',   'Chronic pain',   'Anterior knee pain',   'Positive single leg hop test',   ' Pain is relieved with medication',   'Fell and landed on a twisted leg']},
        
        {name: 'Ligament sprain (MCL)', symptoms:  ['Medial knee pain',   'Moderate to severe pain',   'Knee joint feels like it is giving away',   ' Knee pain aggravated by prolonged standing/walking',   'Fell and landed on a twisted leg',   'Pain is relieved with medication', 'Dull and Achy pain',   'Knee pain eased with rest',   'minor to moderate pain']},
        
        {name: 'Ligament sprain ( LCL)', symptoms:   ['Lateral knee pain',   'Moderate to severe pain',   'Knee joint feels like it is giving away',   ' Knee pain aggravated by prolonged standing/walking',   'Fell and landed on a twisted leg',   'Pain is relieved with medication', 'Dull and Achy pain',   'Knee pain eased with rest',   'minor to moderate pain']},
        
        {name: 'Ligament sprain (PCL)', symptoms:    ['Medial knee pain',   'Moderate to severe pain',   'Knee joint feels like it is giving away',   ' Knee pain aggravated by prolonged standing/walking',   'Fell and landed on a twisted leg',   'Pain is relieved with medication', 'Dull and Achy pain',   'Knee pain eased with rest',   'minor to moderate pain']},
        
        {name: 'Fracture (Subchondral or Intraarticular)',  symptoms: ['Constant and severe bony tenderness',   'Pain is aggravated on weight bearing',   'Moderate to severe pain',   'Gelling at the Knee joint',   'Knee pain aggravated with prolong standing/walking',   'Over 50 years',   'Takes over 15 bottles of alcohol',   ' Fell and landed on the knee joint/joints',   'Positive single leg hop test']},
          
        {name: 'Chondromalacia patella', symptoms: ['Anterior knee pain',   'Retropatella pain',   ' Fell and landed on knee joint',   'Patella unstable on movement',   'Crepitus/clicking at knee joint',   'Knee pain eased with rest ',   'Knee pain aggravated by stair climbing',   'Knee pain is not aggravated by prolonged standing /walking']},
          
        {name: 'Fat pad syndrome', symptoms:  ['Knee pain aggravated by prolonged standing/ walking',   'Knee pain aggravated by stair climbing', 'Moderate to severe pain',   'Anterior knee pain',   'Pain not aggravated by palpation',   'Retropatella pain',   'Joint crepitus at knee joint ']},
        
        {name: 'Knee Osteoarthritis', symptoms: ['Moderate to severe pain ',   'Dull and achy pain',   'Pain has been progressively worsening',   'Chronic pain ',   'Crepitus/clicking at knee joint',   'Knee pain aggravated by prolonged standing/ walking',   'Knee pain aggravated by stair climbing',   'Knee pain aggravated by prolonged sitting',   'Gelling at knee joint',   'Over 50 years']},
        
        {name: 'Pre patella bursitis', symptoms: ['Minor to moderate pain',   ' Knee pain aggravated by prolonged standing/ walking',   'Knee pain aggravated by stair climbing',   'Knee pain eased with rest',   'Fell and landed on knee joint']}, 
        
        {name: 'Plica', symptoms: ['Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Anterior knee pain',   'Medial knee pain',   'Intermittent pain',   'Knee pain aggravated by prolong sitting',   'Knee pain aggravated by stair climbing']},
     
        {name: 'Osgood schlatter disease', symptoms: ['Minor to moderate pain',   ' Moderate to severe pain',   'Knee pain eased with rest',   'Knee pain aggravated by stair climbing',   'Anterior knee pain',   'Superior tibia pain',   'Inferior knee pain',   'Pain is relieved with medication']} ,
        
         {name: 'Patellofemoral pain syndrome', symptoms:  ['Dull and achy pain',   'Retropatella pain',   'Knee pain aggravated by sitting',   'Knee pain aggravated by prolonged standing/ walking',   'Knee pain aggravated by stair climbing']},
   
         {name: 'Cyclist knee', symptoms: ['Anterior knee pain',   'Posterior knee pain',   'Lateral knee pain',   'Medial knee pain',   'Chronic pain ',   'Knee joint feels unstable',   'Dull and achy pain',   'Minor to moderate pain',  'Pain has been progressively worsening',   'Knee pain eased with rest',   'Cyclist']}
      
    ],
    

    'Elbow' :[

    {name: 'Medial epicondylitis', symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Pain aggravated by palpation',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Chronic pain',   'Mid-elbow pain',   'Medial elbow pain',   'Elbow pain started after a fall on affected arm',   'Difficulty with overhead activity on affected arm',   'Plays golf often',   'Limited multi-directional AROM at elbow joint',   'Stiff and restricted elbow joint',   'Elbow pain is relieved by rest']},
    
    {name: 'Lateral epicondylitis', symptoms: ['Moderate to severe pain',   'Sharp',   'sudden pain',   'Pain aggravated by palpation',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Chronic pain',   'Lateral elbow pain',   'Diffuse elbow pain',   'Difficulty with overhead activity on affected arm',   'Engage in overhead sport (badminton',   'long tennis)',   'Limited multi-directional AROM at elbow joint',   'Overhead movement of affected arm aggravate elbow pain',   'Stiff and restricted elbow joint',   'Elbow pain is relieved by rest']},
    
    {name: 'Long head of biceps strain', symptoms: [  'Moderate to severe pain',   'Sharp pain followed by a dull ache',   'Pain aggravated by palpation',   ' Pain relieved with medication',   'Pain has been progressively worsening',   'Mid-elbow pain',   'Elbow pain started after a fall on affected arm',   ' Difficulty with overhead activity on affected arm',   'Engage in overhead sport (badminton',   'long tennis)',   'Pain with weight bearing on affected elbow',   'Limited multi-directional AROM at elbow joint',   'Overhead movement of affected arm aggravate elbow pain',   'Stiff and restricted elbow joint',   'Elbow pain is relieved by rest']},
    
    {name: 'Radial tunnel syndrome',  symptoms:[  'Moderate to severe pain',   'Dull and achy pain',   'Pain aggravated by palpation',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Chronic pain',   'Lateral elbow pain',   'Elbow pain started after a fall on affected arm',   'Difficulty with overhead activity on affected arm',   'Engage in overhead sport (badminton',   'long tennis)',   'Pain with weight bearing on affected elbow pain',   'Limited multi-directional AROM at elbow joint',   'Overhead movement of affected arm aggravate elbow pain',   ' Stiff and restricted elbow joint',   'Elbow pain is relieved by rest',   'Early morning stiffness on affected elbow joint']},
    
    {name: 'Olecranon bursitis', symptoms: ['Minor to moderate pain',   'Pain aggravated by palpation',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Posterior elbow pain',   'Swollen olecranon',   'Elbow pain started after a fall on affected arm',   'Pain with weight bearing on affected elbow',   'Limited multi-directional AROM at elbow joint',   'Overhead movement of affected arm aggravate elbow pain',   'Stiff and restricted elbow joint',   'Elbow pain is relieved by rest',   'Early morning stiffness on affected elbow joint']},
    
    {name: 'Olecranon fracture', symptoms: [  'Sudden intense pain',   'Pain aggravated by palpation',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Posterior elbow pain',   'Elbow pain started after a fall on affected arm',   'Elbow joint feels unstable',   'Pain with weight bearing on affected elbow',   ' Limited multi-directional AROM at elbow joint',   'Crepitus at affected elbow joint',   ' Stiff and restricted elbow joint',   'Elbow pain is relieved by rest']},
    
    ],
    
    
   'Finger' : [
    {name: 'Jersey finger', symptoms: [  'Moderate to severe pain',   'Pain aggravated by palpation',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Chronic pain',   'Pain at finger tip',   ' Monoarticular finger pain',   ' Early morning stiffness in the fingers for about an hour',   'Inability to make a fist',   ' Crepitus on movement of fingers',   'Progressively weaker grip strength',   'Restricted and stiff fingers',   'Finger pain relieved with rest',   ' Pulled a shirt during sport']}
    
    ],

    'Head & Neck' : [

        {name: 'Whiplash', symptoms: ['Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Pain on bilateral UFT',   'Pain has been progressively improving',   'Pain is aggravated on palpation',   'One-sided neck pain',   'Shoulder/UFT aches when lifting things with affected hand',   'Pain is aggravated by neck rotation',   'Pain is relieved by rest',   'Pain is aggravated by neck flexion',   'Cervicogenic headache',   'Movement deficit in the cervical spine',   'Neck pain post road traffic accident ']},
        
        {name: 'TMJ Disorder', symptoms:  ['Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Pain aggravated by chewing and/or yawning',   'Pain over TMJ']},
        
        
        {name: 'Cervical Radiculopathy', symptoms: ['Minor to moderate pain',   'Intermittent pain',   'Pain not aggravated by palpation',   'Chronic pain',   'Pain is aggravated by cough or sneeze',   'Radicular pain radiating down one arm',   'Pain is aggravated by neck rotation',   'Pain is aggravated by neck flexion',   'Pain is aggravated by neck extension',   'Pain is eased by neck flexion',   'Over 50 years']}
        
    ],
    

    'Hip' : [


    {name: 'Hip ligament pathology',symptoms: ['Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Lateral hip pain',   'Anterior hip pain',   'Hip pain is relieved with rest',   'Hip joint feels unstable',   'Antalgic gait on the affected leg',   'Giving way feeling at the hip joint',   'Traumatic history on symptomatic leg',   'Gelling at the hip',   'Pain is aggravated by prolong standing or/and walking']},
    
     
    {name: 'Gluteal tendinopathy pain syndrome', symptoms: ['Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Lateral hip pain',   'Pain is aggravated by prolong standing or/and walking',   'Hip pain is relieved with rest',   'Antalgic gait on the affected leg',   'Hip pain is aggravated with stairs climbing',   'Positive single leg stance test',   'Recently started glute exercises at the gym',   'Pain is of insidious onset']},
    
    
    
    {name: 'Hip OA', symptoms : ['Over 50 year',   'Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Lateral hip pain',   'Anterior hip pain',   'Gelling at the hip',   'Hip joint crepitus',   'Early morning stiffness at the hip',   'Pain is aggravated by prolong standing or/and walking',   'Diffuse hip pain',   'Bilateral symptoms',   'Pain is of insidious onset']},
    
    {name: 'Groin strain', symptoms: ['Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Groin pain',   'Anterior hip pain',   'Hip pain is relieved with rest',   'Antalgic gait on the affected leg']},
    
    {name: 'Referred Hip pain', symptoms: ['Diffuse hip pain',   'Pain not aggravated by palpation',   ' Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is eased post meal intake',   'Hip pain is aggravated by back extension',   'Pain is aggravated post bowel/bladder usage',   ' Pain is aggravated post bowel/bladder usage']},
  
    ],

    'Glute': [
    
    
    {name: 'Deep gluteal syndrome', symptoms: ['Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Gluteal pain',   'Gluteal pain aggravated by prolong sitting',   'Gluteal pain aggravated with prolong standing/walking',   'Radicular symptoms down on leg',   'Pain aggravated by palpation']},
    
    {name: 'Gluteal DOMS', symptoms: ['Recently started gluteal exercises',   'Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Bilateral gluteal pain',   'Gluteal pain aggravated by stair climbing',   'Pain aggravated by palpation']},
    
    ],
    
    
    'Thigh' : [
    
    {name: 'Hamstring strain', symptoms: ['Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Posterior thigh pain',   'Thigh pain aggravated by lumbar forward flexion',   'pain runs down from the hip to the knee',   'Thigh pain eased with rest',   'Hamstring tightness',   'Pain aggravated by palpation',   'Feels like a pulled muscle',   'Sport-related muscle pathology']},
    
    {name: 'Groin strain', symptoms: ['Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Groin pain',   'Anterior hip pain',   'Hip pain is relieved with rest',   'Antalgic gait on the affected leg']},
    
    {name: 'Quadricep strain', symptoms: ['Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Anterior thigh pain', 'Thigh pain aggravated by prolong sitting',   ' pain runs down from the hip to the knee',   'Thigh pain eased with rest',   'Quadriceps tightness',   'Pain aggravated by palpation',   'Feels like a pulled muscle',   'Sport-related muscle pathology']},
    
    {name: 'Proximal iliotibial band syndrome', symptoms: ['Moderate to severe pain',   'Dull and Achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Pain has been progressively worsening',   'Lateral thigh pain',   'Thigh pain aggravated with prolong standing/walking',   'Passive movement does not aggravate pain']}
    
    ],
    
    'Leg': [
    
    {name: 'Leg compartment syndrome', symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Pain aggravated by palpation',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Chronic pain',   'Anterior leg pain',   'Posterior leg pain',   'Pulsating area in the leg',   'Leg pain aggravated by heel walking',   ' Leg pain aggravated with prolonged standing/walking',   'Leg pain runs down from the knee to the foot',   'Leg pain eased with rest',   'Leg pain aggravated by stair climbing']},
    
    {name: 'Blount disease', symptoms: ['Moderate to severe pain',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Chronic pain',   'Medial leg pain']},
    
    {name: 'Leg claudication', symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Pain aggravated by palpation',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Posterior leg pain', 'Calf pain',   'Leg pain aggravated by lumbar forward flexion', 'Leg pain aggravated with prolonged standing/walking',  'Leg pain eased with rest',   ' Leg pain aggravated by stair climbing',   'Calf muscle feels tight',   'Presently taking anticoagulants']},
    
    {name: 'Gastrocnemius strain', symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Pain aggravated by palpation',   'Pain relieved with medication',   'Pain has been progressively worsening',   'Posterior leg pain',   ' Calf pain',   'Leg pain aggravated by toe walking',   'Leg pain aggravated by heel walking',   'Leg pain aggravated with prolonged standing/walking',   ' Leg pain eased with rest',   ' Leg pain aggravated by stair climbing',   'Calf muscle feels tight',   'Swollen',   'tender and warm calf muscle']},
    
    {name: 'Tibialis anterior tendinopathy', symptoms: ['Moderate to severe pain',   ' Dull and achy pain',   ' Pain aggravated by palpation',   ' Pain relieved with medication',   'Pain has been progressively worsening',   'Chronic pain',   'Anterior leg pain',   'Leg pain aggravated by toe walking',   'Leg pain aggravated by heel walking',   ' Leg pain aggravated with prolonged standing/walking',   'Leg pain runs down from the knee to the foot',   ' Leg pain aggravated by stair climbing']}
    
    ],
    
    'Ankle & Foot': [
    
    
    {name: 'Ankle Osteoarthritis', symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Posterior ankle pain',   'Pain is aggravated with standing or walking',   'Crepitus at my ankle joint',   'Early morning stiffness at the ankle for about an hour',   'Pain is relieved with rest']},
    
    {name: 'Ankle sprain', symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Pain is aggravated with standing or walking',   'Ankle joint feels unstable',   'Landed on inverted foot',   'Landed on everted foot']},
    
    {name: 'Anterior ankle impingement syndrome', symptoms: ['Anterior ankle pain',   'Chronic pain',   'Moderate to severe pain',   'Dull and achy pain',   'Pain is relieved with medication',   'Ankle joint feels unstable']},
    
    {name: 'Medial tibial stress syndrome', symptoms: ['Pain on plantar surface',   'Diffuse pain',   'Positive single leg hop test',   'Moderate to severe pain',   'Minor to moderate pain',   'Pain is relieved with medication',   'Pain is relieved with rest']},
    
    {name: 'Archilles tendinopathy', symptoms: ['Moderate to severe pain',   'Dull and achy pain',   'Minor to moderate pain',   'Pain is relieved with medication',   '',   'Crepitus at my ankle joint',   'Positive single leg hop test',   'Early morning stiffness at the ankle for about an hour',   'Early morning stiffness at the ankle for over one hour',   'Over 50years',   'Chronic pain']},
    
     {name: 'Subtalar dislocation', symptoms : ['Moderate to severe pain',   'Minor to moderate pain',   'Ankle joint feels unstable',   'Landed on inverted foot',   'Landed on everted foot',   'Flat foot']}
],

    'Back' : [
    
   {name: 'Non-specific Low Back Pain',
    symptoms: ['Moderate to severe pain', 'Dull and achy pain', 'Minor to moderate pain',   'Pain is relieved with medication', 'Minor to moderate pain', 'Intermittent back pain', 'Pain has been progressively improving', 'Back pain is relived by rest', 'Back pain is aggravated on palpation', 'Unilateral back pain', 'Pain is of an insidious onset']},
   
    {name: 'Serious/Specific Low Back Pain (check report)' ,
    symptoms: ['Moderate to severe pain', 'Early morning back stiffness', 'Minor to moderate pain',   'Pain is relieved with medication', 'Saddle anesthesia', 'Over 50 years', 'Back pain is relived by rest', 'Back pain is aggravated on palpation', 'Saddle anesthesia', `Back pain is not relieved by rest `, `Loss of balance`, `Severe, sharp and constant back pain`, `Pain has been progressively worsening`, `Back pain is aggravated with cough/sneeze`, `Pain across the whole back`, `Takes over 15 bottles of alcohol`, `Bony tenderness on spinal bone`]},



  {name: 'SIJ dysfucntion',
    symptoms: ['Moderate to severe pain', 'Dull and achy pain', 'Minor to moderate pain',   'Pain is relieved with medication', 'Minor to moderate pain', 'Back pain is aggravated by lumbar flexion', 'Back pain is relived by rest', 'Back pain is aggravated on palpation', 'Unilateral back pain', 'Pain is of an insidious onset', `Bilateral sacral pain`]},


  {name: 'Disc Pathology',
    symptoms: ['Moderate to severe pain', 'Dull and achy pain', 'Minor to moderate pain',   'Pain is relieved with medication', 'Minor to moderate pain', `Back pain is eased by lumbar extension
`, 'Back pain is aggravated by lumbar flexion', 'Back pain is relived by rest', 'Back pain is aggravated on palpation', 'Lumbar radiculopathy/ Sciatica', `Bilateral sacral pain`, `Over 50 years`, `Back pain is aggravated by prolong standing/walking
`]},


]

}

export default AllCondition