<template >
   
   <div class="success-signup" v-show="showSuccess">
    Hello {{newUser.FirstName}}! Get ready to share yoursymptoms with us in <span>{{ counter }}</span>
    </div>

    
    <div :class="this.showSuccess? 'gateway success': 'gateway' ">

 <div class="image-dis">
   

    <p class="details">Your <br> <span>Rehabilitation</span> Journey Starts <br> Here</p>
   <div class="img">
    <Imagedisplay/>
   </div>

</div>

 <div class="gateway-dis">
    <div class="content"  v-show="showGatewayContent">
                <div class="gateway-title">
                        <p>
                                Please choose from the options below 
                        </p>
                </div>
                <div class="gateway-options">

                            <div class="option">
                                <div class="option-title">
                                        <p id="title">
                                            I Want My Data Saved
                                        </p>
                                        <p id="details">Create new account</p>
                                </div>

                                <div class="option-img" @click="showSignup ">
                                    <img  id="arrowimg" src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  

                                </div>
                            
                            </div>



                            <div class="option">
                                <div class="option-title">
                                    <p id="title">Single Time User</p>
                                        <p id="details">I dont need a Rehabify account</p>
                                </div>

                                <div class="option-img" @click=" gatewayClicked">
                                    <img id="arrowimg" src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  

                                </div>
                            
                            </div>

                            <div class="option">
                                <div class="option-title">
                                    <p id="title">I Have A Reference Number</p>
                                        <p id="details">I was referred here by a Physiotherapist</p>
                                </div>

                                <div class="option-img" @click="gatewayClicked(); showReference() " >
                                    <img  id="arrowimg" src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  

                                </div>
                            
                            </div>
                </div>
        </div>
            

        <div class="form" >

  
            <div id="form" v-show="!showGatewayContent">
               <div class="form-content">
                <h1>SINGLE TIME USER FORM</h1>

                       <form  @submit.prevent="checkError" >

                        <div class="firstname">
                                <label for="firstname">First Name
                                    <span style="color: red !important; display: inline; float: none;">*</span>
                                </label>
                                <input type="text" :class="(this.FirstNameError? 'active' : '' )"  v-model="newUser.FirstName"  autocomplete="cc-given-name" id="firstname">
                                <span v-show="FirstNameError">{{errorMessage.FirstName}}</span>
                            </div>

                        <div class="lastname">
                                <label for="lastname">Last Name
                                    <span style="color: red !important; display: inline; float: none;">*</span>
                                </label>
                                <input type="text" :class="(this.LastNameError? 'active' : '' )"  v-model="newUser.LastName"  autocomplete="cc-family-name" id="lastname">
                                <span v-show="LastNameError">{{errorMessage.LastName}}</span>
                            </div>


                        <div class="DOB">
                                <label for="birth">DOB
                                    <span style="color: red !important; display: inline; float: none;">*</span>
                                </label>
                                <input v-model="newUser.DOB" type="date" :class="(this.DOBError? 'active' : '' )"  id="birth">
                                <span v-show="DOBError">{{errorMessage.DOB}}</span>
                            </div>

                        <div class="gender">
                                <label for="gender">Gender
                                    <span style="color: red !important; display: inline; float: none;">*</span>
                                </label>
                                <select v-model="newUser.Gender" :class="(this.GenderError? 'active' : '' )"  name="" id="gender" >
                                    <option value="">Please select</option>
                                    <option value="male">Male</option>
                                    <option value="male">Female</option>
                                </select>
                                <span v-show="GenderError">{{errorMessage.Gender}}</span>
                        </div>

                        <div class="email">
                                <label for="email">Email
                                    <span style="color: red !important; display: inline; float: none;">*</span>
                                </label>
                                <input type="email" :class="(this.EmailError? 'active' : '' )"  v-model="newUser.Email"  autocomplete="email" id="email">
                                <span v-show="EmailError">{{errorMessage.Email}}</span>
                            </div>

                        <!-- <div class="serviceProvider" v-show="referenceNumberRequired">
                                <label for="serviceProvider">Service Provider
                                    <span style="color: red !important; display: inline; float: none;">*</span>
                                </label>
                                <select name="" who v-model="newUser.Provider" :class="(this.ProviderError? 'active' : '' )" id="serviceProvider" required>
                                    <option value="male">Please select</option>
                                    <option value="male">ADT PT</option>
                                    <option value="male">MLS OT </option>
                                    <option value="male">Private Professional </option>
                                </select>
                                <span v-show="ProviderError">{{errorMessage.Provider}}</span>
                        </div> -->


                        <div class="reference" v-show="referenceNumberRequired">
                                <label for="reference">Reference Number
                                    <span style="color: red !important; display: inline; float: none;">*</span>
                                </label>
                                <input type="text" placeholder="What numbers were you given? "  :class="(this.RefError? 'active' : '' )" v-model="newUser.Ref"  id="reference" required>
                                <span v-show="RefError">{{errorMessage.Ref}}</span>
                            </div>

                        <div class="TC">
            
                                <input v-model="newUser.TC" type="checkbox" id="TC">
                                    <label for="TC"><a href="http://physiopedia.com"  target="_blank">Accept our Terms and Conditions</a></label>  
                            </div>
                            <span id="TCerror" v-show="TCError">{{errorMessage.TC}}</span>

                        <div id="buttons">
                                    <button type="reset" @click="showGateway" >
                                            <div class="option-img"  >
                                                    <img src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-left.svg" alt="" srcset="">  
                                                </div>
                            
                                    </button>


                                    <button id="goback" type="submit" @click="checkError" >
                                                <div class="option-img"  >
                                                    <img src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  
                                                </div>
                                    </button>       
                         </div>
                 </form>

                 
               


                     </div>


            </div>


        </div>
 </div>


    </div>
 
 </template>
 
 
 <script>
import Imagedisplay from '../components/Imagedisplay.vue';
import {mapActions} from 'vuex'


 export default {

 
 name: 'chat-component',
 components:{
    Imagedisplay
    
 },
 data(){
    return {
        showSuccess:false,
        counter: 5,
        showGatewayForm: false,
        showGatewayContent: true,
        referenceNumberRequired: false,
        
        showError: false,
        alldetails:[],
       
        newUser: {
            FirstName: null ,
            LastName: null,
            Gender: null,
            DOB: null,
            TC: false,
            Ref: null,
            Provider: null,
            Email: null
        },

        
        errorMessage: {
                    TC : null,
                    Ref: null,
                    Provider: null,
                    Gender: null,
                    DOB: null,
                    FirstName: null,
                    LastName: null,
                    Email:null
                  
          },          
                 
        DOBError: false,
        FirstNameError: false,
        LastNameError: false,
        EmailError: false,
        TCError: false,
        GenderError: false,
        RefError: false,
        ProviderError: false,

                        
        
    }
 },

 beforeRouteLeave(){
    this.showGatewayContent= true
        this.showGatewayForm= false 
        this.referenceNumberRequired = false
    
 },
 methods:{

    ...mapActions(['getRandom', 'updateAuth']),
    gatewayClicked(){

        this.showGatewayContent= false
        this.showGatewayForm= true
       
        
    },

    
    //check error before taking user to the symptoms and also gather data from gateway

    checkError(e){
      
        e.preventDefault()
//    let User= this.newUser;
   let error = []
   let noref= ['FirstName', 'LastName', 'DOB', 'Gender', 'TC', 'Email']
   let withRef = ['FirstName', 'LastName', 'DOB', 'TC', 'Gender', 'Ref', 'Provider', 'Email'];

    for (var each in this.newUser){
        if ( !this.newUser[each] ){
            error.push(each)
            this[`${each}Error`]= true ;
          
                    if(each === 'TC'){
                            this.errorMessage[`${each}`] = `Kindly accept our terms and conditons`
                        }
                
                else{
                this.errorMessage[`${each}`] = `Required answer`
                }
} else{
    error.filter(err => err !== each)

    this[`${each}Error`]= false;
    this.errorMessage[`${each}`] = ''
}

    }

    if (this.referenceNumberRequired && error.every(err => !withRef.includes(err))){
    
    
        this.sendRandomUser()
        // this.updateAuth()
        localStorage.setItem('Rehabify', true)

      
    }

    if (!this.referenceNumberRequired && error.every(err => !noref.includes(err))){

        
        this.sendRandomUser()
        localStorage.setItem('Rehabify', true)
        // this.updateAuth()
    
}
    
           
            

    },
   

    // takes user back to the gateway page and clears the old collected data
    showGateway(){

        this.DOBError= false
        this.FirstNameError= false
        this.LastNameError= false
        this.TCError= false
        this.GenderError= false
        this.RefError= false
        this.ProviderError= false
        this.EmailError= false


        this.showGatewayContent= !this.showGatewayContent
        this.showGatewayForm= false

    

        this.referenceNumberRequired = false
        this.newUser.FirstName= null
        this.newUser.LastName= null
        this.newUser.DOB= null
        this.newUser.Gender=null
        this.newUser.TC= false
        this.showError= false
  
      
        
    },

    //shows the reference input and  and service provider 
    showReference(){
        this.referenceNumberRequired = true
    },

    // takes the user to the sign up page 
    showSignup(){
        this.$router.replace({
              path: '/signup',
             
        })
    },

    //form validation and error display 
    sendRandomUser(){
let today = new Date ().getFullYear()
let myDOB = new Date (this.newUser.DOB).getFullYear()
let age= (today - myDOB)

        let user={
            // firstname: this.newUser.FirstName,
            // lastname: this.newUser.LastName,
            fullname: `${this.newUser.LastName} ${this.newUser.FirstName}`,
            gender: this.newUser.Gender,
            TC: this.newUser.TC,
            ref: this.newUser.Ref,
            email: this.newUser.Email,
            serviceProvider: this.newUser.Provider,
            DOB: age,

        }

      // this sends the random user data to Vuex User module
        this.getRandom(user)
      
        this.showSuccess = true
           let displayCounter= setInterval(()=>{
               
                this.counter = this.counter - 1

            },1000);
             
        
        // this sends the user to the symptoms page
    setTimeout(()=>{
        clearInterval(displayCounter)
        this.$router.replace({

path: `/symptom/${this.newUser.FirstName}`
})
    }, 5000)
     
    }
 }
 

 }
 </script>
 
 
 <style scoped>
 
 
 
 </style>

















