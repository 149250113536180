<template>

   <div class="email">
        
     <div class="symptom-container">



           <div class="first-part">
           <h1>
                 
           </h1>
           </div>


        <div class="second-part">

        </div>


           <div class="main-part">




              
<div class="gateway-title">
               <p>
                   Email Report 
               </p>
</div>

<div class="gateway-options" :class="this.showSuccess? 'active': ''">


   <div class="email-content">

               <form :class="this.showSuccess? 'active': ''">
                  <div id="email">
                  <label for="email">Recipient's Email
                  <span style="color: red !important; display: inline; float: none;">*</span> </label>
                      <input type="email" :class="this.error? 'error': ''" v-model="emailto" name="" id="email">

               </div>

            <div id="note">
               <label for="note">Extra Info</label>
            <textarea name="" id="note" v-model="extranote" cols="30" maxlength="200" rows="10"></textarea>

            </div>

                  <input type="button"  @click="sendEmail" :value="buttonText">
               </form>



               
                        <div class="Email_success" v-show="showSuccess">
                    Report Successfully sent to {{emailto}}. You can now close this page.
                     </div>


   </div>

  
                 
</div>
              </div>

   </div>


   </div>

</template>

<script>
import emailjs from '@emailjs/browser';

export default {

name: 'Email-component' ,
data(){
   return{
         buttonText: 'Send Email',
         emailto: null,
         extranote: null,
         error: false,
         showSuccess:false
   }
},
components:{
 
},
methods:{

  

   sendEmail() {

      let User = JSON.parse(localStorage.getItem('User'));
   let fullname = User.fullname
   let Diagnosis = JSON.parse(localStorage.getItem('Diagnosis'));
   // let diagnosis_short = Diagnosis.shortSummmary
   
      
      if (this.emailto){
         this.error = false
         emailjs.send("service_fee1bs7","template_yl29h08",{
            fullname: fullname,
            email_to: this.emailto,
            patient_email: User.email,
            message: Diagnosis
            }, 'FAyuUfmN6E_CoRO0j').then((result) => {
           this.showSuccess= true
            console.log(result.text)
        }, () => {
         
         setInterval(() => {
            this.buttonText = 'failed'
         }, 1000);
        });
      }
      else{
         this.error = true
      }
}
}


}
</script>


<style scoped>

.email .gateway-options{
  width: 100%;
  margin: auto;
  height: 100%;
}

form.active{
 
 
   filter: blur(1.2px);
  
}


.email .Email_success{
   margin-bottom: 20px;
    border: 2px solid;
    background-color: white;
    color: white;
    font-weight: 700;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:80%;
    max-width: 500px;
    text-align: center;
    padding: 50px;
border-radius: 15px;
z-index: 7;
background-image: url('https://i.imgur.com/Q9BGTuy.png');
background-repeat: no-repeat;
background-position: 10px center;
background-color: rgb(7, 85, 11);
filter: blur(0px);
 
}
.email .options{

width: 100%;
margin: auto;


}

.email .gateway-title {
  background-color: var(--colorB);
  padding: 10px;
}
.email .gateway-title p{
  text-align: center;
  font-weight: 700;
  color: white
  ;
}

.email-content{
   display: grid;
   place-content: center;

   height: 80%;
   width: 100%;
  

}
#email{
   margin-bottom: 20px;
} 

#email, #note{

   max-width: 600px;
   width: 320px;
} 
label{
   width: 100%;
   color: var(--colorB);
}
textarea{
   height: 150px;
   border: 1px solid var(--colorAdim);
   background-color: white;
   font-size: 1rem;
}

input[type="email"] {
   padding: 10px;
   font-size: 1rem;
   border: 1px solid var(--colorAdim);
   background-color: white;

}

input[type="email"].error {
 
   border: 2px solid red
  
}

input:focus, textarea:focus{
   border: 2px solid var(--colorB);
}

input[type="button"]{
   margin-top: 20px;
   color: white;
   background-color:  var(--colorB);
   font-size: 1.3rem;

}

input[type="button"]:hover{
   
   background-color:  var(--colorBdim);
 
}




/* input[type="button"]:hover{
  
   background-color:  var(--colorB);
   opacity: .8;
   cursor: pointer;
} */



</style>