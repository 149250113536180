<template>

   <div class="about">
 
 
 
     <div class="report">
              <div class="title">
                 <Logo />
                <h2> REHABIFY CASE REPORT -PROFESSIONAL COPY
 
                </h2>
 
 
              </div>
 
            <div class="report-details">
              <section id="patient">
              <h4 style="color: red;">PATIENT INFORMATION</h4>
              
              <div class="patient-details">
                 <div class="container">
                    <div class="each">
                       <b>Name</b>
                       <p>{{fullname}}</p>
 
                    </div>
                    <div class="each">
                       <b>Age</b>
                       <p>{{age}}</p>
 
                    </div>
                    <div class="each">
                       <b>Gender</b>
                       <p>{{gender}}</p>
 
                    </div>
                    <div class="each">
                       <b>Email</b>
                       <p>{{ email }}</p>
 
                    </div>
                 </div>
 
              </div>
 
             </section>
 
 
             <section id="clinical">
              <h4 style="color: red;">RED FLAG SUMMARY</h4>
              
              <div class="patient-details">
                 <div class="container">
                   <div class="each">
                
                     <p style="margin-top: 100px;">To Whom It May Concern, <br> <br>

                                 I am referring my patient {{ fullname }}, to your facility for further evaluation due to red flags reported during a recent online self-triage assessment. The patient reported experiencing symptoms which are concerning and require prompt attention.
                                 These includes: <br> <br>
                                 <span style="display: block;" v-for="(each, index) of currentDiagnosis" :key="index">
                                    {{ index + 1 }}. {{ each }}
                                 </span>
                                 <br>
                                 <br>                          
                                 I value your experience in evaluating and managing patients with these types of symptoms, and I am confident that your facility will offer the necessary care and treatment. 
                                 Once the patient has been cleared of any serious pathologies, they can be directed to complete the assessment.
                                 <br>
                                 <br>
                                 <br>
                                    Best regards, <br> 
                                    Dr Ademola Abass (PT) <br> 
                                    <span style="color: var(--colorB);">Rehabify's Chief Physiotherapist</span>

</p>
                   
                   </div>

                   <div class="each"></div>
 
                   
<!--              
               <div class="each">
                     <p id="impression"></p> 
                     <div v-for="(each, index) of currentDiagnosis" :key="index">
                                 <p>{{ index + 1 }}. {{ each }}</p>
                       </div>

 
                    
                   </div>
  -->
                
                
                   
               
            
            
                   
                 </div>
              </div>
 
             </section>
 
            </div>
     </div>
 
 
   </div>
 
 </template>
 
 
 <script>
 import Logo from '../components/logo'
 export default {
 
 name: 'about-component' ,
 components:{
 Logo
 
 },
 data(){
   return{
      user: this.User,
      diagnosis: this.Diagnosis,
   currentDiagnosis: null,
   fullname: null,
   age:null,
   email: null,
   gender: null
   }
},
mounted(){
 
  
let foundUser =  localStorage.getItem('User')?  JSON.parse(localStorage.getItem('User')): {}
  
  if (foundUser){
    this.fullname = foundUser.fullname
    this.age = foundUser.DOB
    this.email= foundUser.email
    this.gender = foundUser.gender
   
   } 
  
  
    let foundSymptoms =  localStorage.getItem('Symptoms')?  JSON.parse(localStorage.getItem('Symptoms')): null
    this.currentDiagnosis= foundSymptoms
  
  


}

 
 
 }
 </script>
 
 
 <style scoped>
 .report{
 height: 1100px;
width: 100%;
max-width: 700px;

margin: auto;
background-color: white;
border: 2px solid var(--colorB);
}

.report-details{
width: 95%;
margin: auto;
height: 90%;
padding: 10px;
display: flex;
flex-direction: column;
justify-content: space-between;



}
.report .title{
height: 8%;
background-color: #103d8ca5;

text-align: center;
display: flex;
align-items: center;
justify-content: center;
color: var(--colorB);
font-size: .8rem;
color: white;
margin-bottom: 20px;
}

.patient-details{
height: 90%;

width: 100%;
padding: 10px;
background-color: var(--colorAdim);
background-color:  #ee9cd936;
/* --colorBdim: #3879e9b5;
;; */
}

section h4 {
color: var(--colorB);
margin-bottom: 10px;
margin-left: 10px;
letter-spacing: .2px;

}


section#patient{
height: 20%;
}
section#clinical{
height: 75%;
}

#patient .container{
height: 100%;
width: 100%;
display: flex;
flex-wrap: wrap;

}
.patient-details .container .each{
 text-align: left;

}

#patient .each {
width: 50%;
height: 50%;
}

#patient .each p{
margin-top: 12px;
}
 
 #clinical .container{
  height: 80%;
 
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  text-align: center;
 
 }
 p#impression{
  font-weight: 700;
  font-size: 1rem;

 }
 p#impression span{
  font-weight: 600;
  color: var(--colorB);
  font-size: 1rem;
 }

 .patient-details .each{
   height: 25%;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
 }
 
 
 </style>