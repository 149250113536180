<template>
<div class="step-image">
    
    <div class="image">

<img :src="(images[counter])" alt="" srcset="">

    </div>
  
     <div class="image-span">
            <span @click="(this.counter= 0); addClass()" :class="this.counter===0? 'active': ''" >1</span>
            <span @click="(this.counter= 1); addClass()" :class="this.counter===1? 'active': ''">2</span>
            <span @click="(this.counter= 2); addClass()" :class="this.counter===2? 'active': ''" >3</span>

    </div> 
    



</div>

</template>


<script>

export default {

name: 'image-component',
data(){

    return{
        images: [],
        counter: 0,
        details:[
       ' Are you a professional? or do you need a quick note to take to the hospital?',
       `Click the 'Start here' button above and answer all the questions you'll be asked.`,
       `At the end, you will get ith percentage of accuracy all based on the answers you've provided.`


        ]
    }
},

methods: {
    addClass(e){
      
            if(e.currentTarget.classList.contain('active')){
                alert('a')
            }
            else{
                e.target.classList.add('active')
            }
    }


},
 created(){
    let a = require('@/assets/img/bg-one.png')
    let b = require ('@/assets/img/bg-two.png')
    let c = require('@/assets/img/bg-three.png')
    this.images.push(a, b, c)
 },
 
 mounted(){
   setInterval(()=>{

    this.counter = this.counter + 1

    if(this.counter >2){
        this.counter = 0
    }
   }, 3000)

 }


      
}
</script>


<style scoped>

.image{
    height:250px;
    width: 250px;
    margin: auto;
   
}

.image img{
    height: 100%;
    width: 100%;
    margin: auto;

    transition: all .1s;
   
    
}

.image-span{
    margin-top: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    margin: auto;
    margin-top: 30px;
  
   
   
}
.image-span span{
    border: 1px solid white;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 30px;
    margin-left: 20px;
    
    text-align: center;
    color: var(--colorA);
    
}


.image-span span:hover, span.active{
    background-color: var(--colorA);
    color: white;
}

@media only screen and (min-width: 740px){
  

    .step-image{
        display: block;
    }
}
    

</style>