<template>


    <div class="login">
        
       <div class="login-error" v-show="Error">
          <p>{{ loginErrorMessage }}</p>
       </div>
       <div class="login-success" id="success" v-show="Success">
          <p style="font-size: .9rem">Success: welcome Admin</p>
       </div>
    
    
    <div class="form" >
    
    <div id="form">
       <div class="form-content">
          <h1>ADMIN LOGIN</h1>
               <form @submit.prevent="loginError" >
    
                <div class="email">
                        <label for="firstname">Email</label>
                        <input  type="email"  v-model=" userEmail"   autocomplete="email" autocapitalize="characters" id="firstname">
                         <span v-show="emailError">{{emailMessage}} hhhshhsh</span>
                    </div>
    
                <div class="password">
                        <label for="password">Password</label>
                        <input   ref="password" type="password"   v-model=" userPassword"  autocomplete="current-password" id="password">
                        <span v-show="passwordError">{{passwordMessage}}hdhhddh</span>
                    </div>
    
    
                    <div class="reset">
                      <p @click="resetPassword"> Reset password? </p>  
                      <p @click="revealPassword" > {{showPassword? 'Hide': 'Show' }} password?</p>  
             
    
                     </div>
    
                   
       
                <div class="button">
                   <button type="submit">LOGIN</button>
                </div>
                <p id="signup">Dont have an account? <b @click="$router.push('/signup')"> Create new account</b></p>
                 
    
              
         </form>
    
             </div>
    
    
    </div>
    
    
    </div>
     
     
    
    
    
    </div>
     
     </template>
     
     
     <script>
    import {auth, db, getDoc, doc, signInWithEmailAndPassword} from '@/main.js'
    
    import {mapActions} from 'vuex'
    
     export default {
     
     name: 'adminlogin-component' ,
     components:{
       
     },
     data(){
       return{
          userEmail: null,
          userPassword:null,
          userTC: true,
          Error: false,
          Success:false,
          showPassword: false,
          passwordMessage:null,
          loginErrorMessage: null,
          emailError: false,
          passwordError:false
       }
     },
    
     methods: {
    
       ...mapActions(['getUser']),
       revealPassword(){
          this.showPassword= !this.showPassword
           this.$refs.password.type = this.showPassword?  'text': 'password'
       },
    
       resetPassword(){
          this.$router.push('/login/reset-password')
       },
    
       
       loginError(){
          
         
          
             if(this.userEmail === null || this.userPassword === null){
               
                let displayError= setInterval(()=>{
                                  
                            this.Error = true
                            this.loginErrorMessage = 'Put in your details'
    
                                  },1000);
                                  
                                  setTimeout(()=>{
                                      clearInterval(displayError)
                                      this.Error= false
                                      this.loginErrorMessage = ''
    
                                  },3000)
                                
                          
                 } 
    
       
             else{
                this.checkFirebase()
             }
       
       },
    async getnewUser(){
       try {
          let AuthUser = auth.currentUser.uid
                               const docRef = doc(db, 'Profile', AuthUser)
                               const docSnap = await getDoc(docRef)
                            
                              if(docSnap.exists()){
                              let loggedinUser= docSnap.data()
                               this.getUser(loggedinUser)
                               localStorage.setItem('User', JSON.stringify(loggedinUser))
                            }
                               
                            } catch (error) {
                               console.log(error)
                            }
    },
       checkFirebase(){
    
    
    
                let user ={
                   email: this.userEmail,
                   password: this.userPassword
                }
    
                signInWithEmailAndPassword(auth, user.email, user.password)
                         .then((AuthUser)=> {
    
                            if(AuthUser.user.emailVerified){
                          
                            this.Success= true
                          
                            this.getnewUser()
                      
                            setTimeout(()=>{
    
                               let user= JSON.parse(localStorage.getItem('User'))
                               let username= user.FirstName
                         
                               // this.$router.push(`/profile/:ademola`) 
                               window.location.replace(`/profile/:${username}`)
                                                   
                            },2000)
                            }
    
                            else{
                               let displayError= setInterval(()=>{
                                  
                                  this.Error = true
                                  this.loginErrorMessage = `Account hasn't been verified`
                             
                                              
                                   },1000);
                                           
                                   setTimeout(()=>{
                                               clearInterval(displayError)
                                               this.Error= false
                                               this.loginErrorMessage = ''
             
                                  },3000)
                            
                            }
                           
                            
                           
                           
                         })
    
                         .catch((err)=> {
                         
                            let displayError= setInterval(()=>{
                                  
                               let errcode = err.code
                               this.Error = true
                                           if (errcode === 'auth/wrong-password' || errcode === 'auth/invalid-password' ){
                                                 this.loginErrorMessage = 'Wrong Password Provided'
                                           }
                                         else  if (errcode === 'auth/user-not-found' || errcode === 'auth/user-disabled' || errcode === 'auth/invalid-email'){
                                                 this.loginErrorMessage = 'User not found'
                                           }
                                           else{
                                              this.loginErrorMessage = err.code
                                           }
                                },1000);
                                        
                                setTimeout(()=>{
                                            clearInterval(displayError)
                                            this.Error= false
                                            this.loginErrorMessage = ''
          
                               },3000)
                                      
                            
                         })  
    
    
    },
    
    
    
      
    
     }
     
     
     }
     </script>
     
     
     <style scoped>
     
    
     </style>