<template>

<div class="symptom-container">



            <div class="first-part">
            <h1>
            
              <span v-if="showbodypart" style="font-size: 13px;"> Hello <span style="color:violet">{{$route.params.firstname}}</span>, you have selected the  <span style="color:violet">{{bodypart}}
                </span> region.</span>
             
            </h1>
            </div>


            <div class="second-part">

<!-- main part of the symptom -->

<div class="main-part">
        
        <div class="first-main">
                <h2>
                {{this.title[0]}}
                </h2>
                  <p>
                    {{this.title[1]}}
                  </p>
        </div>

        <div class="second-main" id="image" v-show="(showMain=== 0)">
               <div class="allcontent">
                      <div class="content-image">
                      
              <img  src="../assets/img/body.jpg" usemap="#image_map">
              

<map name="#image_map" @click="checkTitle">
<area target="" alt="Chest" title="Chest"  coords="89,89,145,128" shape="rect">
<area target="" alt="Head &amp; Neck" title="Head &amp; Neck"  coords="95,34,140,88" shape="rect">
<area target="" alt="Head &amp; Neck" title="Head &amp; Neck"  coords="309,86,264,27" shape="rect">
<area target="" alt="Back" title="Back"  coords="259,87,319,194" shape="rect">
<area target="" alt="Glute" title="Glute"  coords="254,195,317,224" shape="rect">
<area target="" alt="Shoulder" title="Shoulder"  coords="78,108,18" shape="circle">
<area target="" alt="Shoulder" title="Shoulder" coords="154,106,17" shape="circle">
<area target="" alt="Shoulder" title="Shoulder"  coords="248,107,20" shape="circle">
<area target="" alt="Shoulder" title="Shoulder" coords="325,105,16" shape="circle">
<area target="" alt="Arm" title="Arm"  coords="61,118,80,148" shape="rect">
<area target="" alt="Arm" title="Arm"  coords="150,120,171,151" shape="rect">
<area target="" alt="Arm" title="Arm"  coords="321,120,340,150" shape="rect">
<area target="" alt="Arm" title="Arm"  coords="229,124,250,149" shape="rect">
<area target="" alt="Elbow" title="Elbow"  coords="68,161,13" shape="circle">
<area target="" alt="Forearm" title="Forearm"  coords="" shape="rect">
<area target="" alt="Elbow" title="Elbow"  coords="163,161,NaN" shape="circle">
<area target="" alt="Elbow" title="Elbow"  coords="162,161,14" shape="circle">
<area target="" alt="Elbow" title="Elbow"  coords="239,161,12" shape="circle">
<area target="" alt="Elbow" title="Elbow"  coords="334,165,13" shape="circle">
<area target="" alt="Forearm" title="Forearm"  coords="54,173,72,201" shape="rect">
<area target="" alt="Forearm" title="Forearm"  coords="152,176,183,198,207,188,227,172,239,191,158,186" shape="rect">
<area target="" alt="Forearm" title="Forearm"  coords="223,170,245,199" shape="rect">
<area target="" alt="Forearm" title="Forearm" coords="325,176,354,197" shape="rect">
<area target="" alt="Wrist" title="Wrist"  coords="333,198,357,215" shape="rect">
<area target="" alt="Wrist" title="Wrist"  coords="45,201,65,220" shape="rect">
<area target="" alt="Wrist" title="Wrist"  coords="165,197,185,219" shape="rect">
<area target="" alt="Wrist" title="Wrist"  coords="214,201,238,217" shape="rect">
<area target="" alt="Fingers" title="Fingers"  coords="339,218,365,236" shape="rect">
<area target="" alt="Fingers" title="Fingers"  coords="235,240,211,217" shape="rect">
<area target="" alt="Fingers" title="Fingers"  coords="167,219,191,238" shape="rect">
<area target="" alt="Fingers" title="Fingers"  coords="41,217,63,241" shape="rect">
<area target="" alt="Thigh" title="Thigh"  coords="254,225,320,266" shape="rect">
<area target="" alt="Thigh" title="Thigh"  coords="82,221,147,262" shape="rect">
<area target="" alt="Ankle &amp; Foot" title="Ankle &amp; Foot"  coords="139,386,92,347" shape="rect">
<area target="" alt="Ankle &amp; Foot" title="Ankle &amp; Foot"  coords="259,352,311,382" shape="rect">
<area target="" alt="Hip" title="Hip" coords="95,210,15" shape="circle">
<area target="" alt="Hip" title="Hip" coords="136,210,13" shape="circle">
<area target="" alt="Knee" title="Knee"  coords="86,264,146,296" shape="rect">
<area target="" alt="Knee" title="Knee"  coords="257,267,317,296" shape="rect">
<area target="" alt="Leg" title="Leg"  coords="88,297,142,345" shape="rect">
<area target="" alt="Leg" title="Leg"  coords="261,293,311,350" shape="rect">

</map>
                      </div>

                  </div>

                  <div class="image-desc">
                    <span>Clickable Body Parts:</span> 
                  <ul>
                    <li>Head and Neck</li>
                    <li>Shoulder</li>
                    <li>Chest</li>
                    <li>Arm</li>
                    <li>Forearm</li>
                    <li>Wrist</li>
                    <li>Fingers</li>
                    <li>Back</li>
                    <li>Glute</li>
                    <li>Hip</li>
                    <li>Thigh</li>
                    <li>Knee</li>
                    <li>Leg</li>
                    <li>Ankle and Foot</li>

                  </ul>
                  </div>

                
        </div>
        <div class="second-main" id=" general Red flag" v-show="showMain=== 1" >

              <div @click="answerButton"  class="flag" v-for="(each, index) in generalFlag" :key="index"  >

                  <div ref="choices" >{{each}}</div>

                  </div>

        </div>


        <div class="second-main" id="Specific-region" v-show="(showMain=== 2)" >

                <div @click="answerButton"  class="flag" v-for="(flag, index) in specificFlag" :key="index"  >

                    <div > {{flag}}</div>
                
                </div>
          
        </div>
        <div class="second-main" id="pain" v-show="(showMain === 3)">
                <div @click="answerButton"  class="flag" v-for="(each, index) in pains" :key="index"  >

                <div> {{each}}</div>

                </div>
        </div>

        <div class="second-main" id="pain" v-show="(showMain === 4)">
                <div @click="answerButton"  class="flag" v-for="(pain, index) in morePain" :key="index"  >

                <div > {{pain}}</div>

                </div>
        </div>
        <div class="second-main" id="Movement" v-show="(showMain=== 5)">
              <div @click="answerButton"  class="flag" v-for="(each, index) in ROM" :key="index"  >

                <div >{{each}}</div>

                </div>
        </div>
        <div class="second-main" id="other" v-show="(showMain=== 6)">
                  <div @click="answerButton"  class="flag" v-for="(each, index) in others" :key="index"  >

                  <div >{{each}}</div>

                  </div>
        </div>
        <div class="second-main" id="result" v-show="(showMain=== 7)">
              <div class="loading" v-show="!this.doneLoading">

                      <div class="loading-message" > <p>{{(loadingText)}}</p></div>
                      <div class="container"  v-show="!this.doneLoading">
                      <div class="loader"></div>
                        </div>     
                </div>

          
                    <div class="diagnosis" v-show="doneLoading" v-for="each in Finaldiagnosis" :key="each.id">

            
                      <div class="each-diagnosis">
                        <div id="diagnosis">
                          <p>{{each.diagnosis}}</p>
                        </div>
                        <div id="percentage">{{each.percentage}} chance</div>
                      </div>
                    </div>

                    <div class="diagnosis-explanation" v-show="this.doneLoading">
                        <p>
                          As an AI language model, I am not a medical professional. 
                          However, based on the symptoms provided, This is a probable medical condition. If you or someone else experiences these symptoms, it's crucial to consult a qualified healthcare professional for an accurate diagnosis and personalized medical advice based on a thorough examination and medical history.
                           Click the button below to connect with a Physiotherapist on Rehabify. <br>  <br>You can download all the information about your condition including other possible diagnosis, by clicking the button below and bring it to your appointment. As Rehabify provides comprehensive information, you can expect a more in-depth assessment.
                        </p>
                      </div>

                    <div class="display-result" v-show="doneLoading">
                         

                    <div class="whatnext" v-show="doneLoading">
                    
                        <div class="options">
                          <router-link :to="{path: `/email-report`}" target="_blank">Email Report</router-link>
                          <router-link :to="{path: `/download-report`}" target="_blank">Download report</router-link>
                          <router-link :to="{path: `/find-a-physio`}" target="_blank">Find a PT</router-link>
                          <router-link :to="{path: `/signup`}">Sign Up</router-link>
                        </div>
                    
                    </div>
              </div>


        </div>

            <div class="third-main">
                        <div class="symptom-button"  v-show="showMain">
                            <button  id="previous" v-if="nowLoading"  @click="previousButton">
                            
                            <img src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-left.svg" alt="" srcset="">   </button>
                          
                           <div v-else> <button   v-if=" this.doneLoading"  id="previous" @click="startOver">
                            
                            <img src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-left.svg" alt="" srcset="">                     
                           
                          </button></div>
                            <button id="next" v-if="(showMain <= 6)" @click="nextButton" >
                            
                              <img src="../assets/fontawesome-free-5.15.4-web/fontawesome-free-5.15.4-web/svgs/solid/arrow-right.svg" alt="" srcset="">  
                            </button>
                          </div>
          </div>


</div>
            </div>

              
 </div>
   





</template>


<script>

import body from '../assets/data' 
import condition from '../assets/condition' 


import {mapActions} from 'vuex'


   

export default {

name: 'symptom-component',
components:{

},
props:['body-part', 'firstname'],
data(){
      return{
        title: null,
        bodypart: '',
        showbodypart:false,
        time: 3,
        showMain: 0,
        generalFlag: null,
        specificFlag:null,
        flags:null,
        pains:null,
        morePain:null,
        category:null,
        ROM:null,
        others: null,
        questionClass:'',
        loadingText: 'Gathering your data',
        nowLoading:true,
        doneLoading:false,
        collectedData: new Set(),
        Finaldiagnosis: [{diagnosis: '', percentage: '', id: null}],
       
      }
},
methods:{
  ...mapActions(['getSymptoms']),
        //  Timer on the symptom page 
        checkTitle(e){
 
          // check the local storage for symptoms and delete it
          let foundSymptoms =  localStorage.getItem('Symptoms')
 if (foundSymptoms){
  localStorage.removeItem('Symptoms')
 }

          this.bodypart= e.target.title;
          this.getQuestions()
        
          this.showbodypart= !this.showbodypart
          let Timer= setInterval(()=>{
            this.time= this.time-1
            if(!this.time ){
              clearInterval(Timer)
              this.showbodypart= false
              this.time= 3
              this.showMain= this.showMain+1
              this.title= body.title[`${this.showMain}`]
              
            }
            
          }, 1000)
         


          // Get clicked part's data

        // let flag = [body.general, body[this.bodypart][0].flag] ;
        // this.flag= flag

        // let pain = [ body[this.bodypart][0].pain] ;
        // this.pain= pain

        // let morePain = [ body[this.bodypart][0].morepain] ;
        // this.morePain= morePain

        // let ROM = [ body[this.bodypart][0].Rom] ;
        // this.ROM= ROM

        // let otherquest = [body[this.bodypart][0].Others] ;
        // this.others= otherquest
        },

        categorySelected(e){
            alert(e.target.value)

        },
        getQuestions(){
            //get general 
                let allFlag = body.General
                this.generalFlag= Object.keys(allFlag)
              
            // //get category
            // let category = body[`${this.bodypart}`].part
            //  this.category = Object.keys(category)
           
             //get pain
             let pain = body[`${this.bodypart}`].pain
            this.pains = Object.keys(pain)
             //get morepain
             let morepain = body[`${this.bodypart}`].morepain
            this.morePain = Object.keys(morepain)
             //get ROM
             let rom = body[`${this.bodypart}`].Rom
            this.ROM = Object.keys(rom)
             //get Others
             let Otherquest = body[`${this.bodypart}`].Others
            this.others = Object.keys(Otherquest)
            //get specific flag
            let specFlag = body[`${this.bodypart}`].flag
            this.specificFlag = Object.keys(specFlag)


        },
        // next and previous button
        nextButton(){
          
        
                  this.checkGeneral()
                  this.showMain= this.showMain+1
                  this.sendData()


                      this.title= body.title[`${this.showMain}`]


                if(this.showMain ===3){
                 this.checkSpecific()
                }

                  // if we are on the last page, send the User's data 
                 
                if(this.showMain===7){
                         
                  let FinalSymptoms = JSON.parse([localStorage.getItem('Symptoms')])
                
                  this.getDiagnosis(condition[`${this.bodypart}`], FinalSymptoms)
                  this.changeText()
                  this.nowLoading= false
                  
 

              }
                },

        previousButton(){
          this.showMain= this.showMain-1
              this.title= body.title[`${this.showMain}`]

        },

        // add the text content of clicked options to the collectedData set. Used add and delete method to remove items 
      
        answerButton(e){
           

          let collected;
          e.currentTarget.classList.toggle('clicked');
        let  clickedButton = e.currentTarget.textContent

             //get general  value
             if(this.showMain === 1){
             let allFlag = body.General
              collected = allFlag[`${clickedButton}`]
             }
         
             //get pain value

             if(this.showMain === 3){ 
              let painquest = body[`${this.bodypart}`].pain
              collected = painquest[`${clickedButton}`]
             }
            
          
             //get morepain value

             if(this.showMain === 4){ 
              let morePainQuest = body[`${this.bodypart}`].morepain
              collected = morePainQuest[`${clickedButton}`]
             }
           
             //get ROM value
             if(this.showMain === 5){ 
              let romQuest = body[`${this.bodypart}`].Rom
              collected = romQuest[`${clickedButton}`]
             }
          
             //get Others value
             let Otherquest = body[`${this.bodypart}`].Others
            this.others = Object.keys(Otherquest)

            if(this.showMain === 6){ 
              let Otherquest = body[`${this.bodypart}`].Others
              collected = Otherquest[`${clickedButton}`]
             }
            //get specific flag value
            if(this.showMain === 2){ 
              let specFlag = body[`${this.bodypart}`].flag
              collected = specFlag[`${clickedButton}`]
             }
           

// add clicked value to the collected data set
          if (e.currentTarget.classList.contains('clicked')){
            
            this.collectedData.add(collected)
      
            
          }
        
          else{
            
            this.collectedData.delete(collected)
            
          }

          // this.checkGeneral()
          },

          sendData(){
           
          
             let Alldata= this.collectedData.values()
 
                  let patientData= []
                  for (const patient of Alldata ){
                    patientData.push(patient)
                  }

                     // send data to Diagnosis state 
                  this.getSymptoms(patientData)
                  this.FinalSymptoms= patientData
                  localStorage.setItem('Symptoms', JSON.stringify(patientData))
                  
                   
          },
        

          // loading text method to change text every two seconds 

        changeText(){
                this.nowLoading= !this.nowLoading
                let displayText=[ 'Processing on our server...', 'oops! I found something', 'one second..']
                let counter= 0
                if(this.showMain===7){
            var setText= setInterval(()=>{
              this.loadingText= displayText[counter]
              counter =counter+1 
            }, 2500)

                }
                setTimeout( ()=>{

                  //set loading text back to default, stop changing displayed text, and display the results
                  this.loadingText= `Based on your answers, you could have:`
                  clearInterval(setText)
                  this.doneLoading= !this.doneLoading
              
                } 
                  , 10000)

                  

                
                 
        },

        // returns the page to home page, removes the startover button and clears the collected data 
        startOver(){
          this.$router.push({name: 'gateway-component'})
          // this.loadingText='Gathering your data'
          //   this.showMain= 0
            this.nowLoading= true
            this.collectedData.clear()    
            
        
        },

        // check general red flag 

        checkGeneral(){
         
          let totalRedFlag= 0
          let allGeneralFlag = []
          let allGeneral = Object.values (body.General)
           allGeneral.forEach(each=>{
           
            if (this.collectedData.has(each)){
            totalRedFlag = totalRedFlag + 1 
            allGeneralFlag.push(each)
            localStorage.setItem('General', JSON.stringify(allGeneralFlag))
           
           if(totalRedFlag >= 2){
              this.$router.push('/urgent')
              this.sendData()
            }
        
          } 
           if (this.collectedData.has(each) && this.showMain === 2){
              this.collectedData.delete(each)
              let indexofEach = allGeneralFlag.indexOf(each)
              allGeneralFlag.splice(indexofEach)
                
                localStorage.setItem('General', JSON.stringify(allGeneralFlag))
            }
         

            
        })


        },

        // Check specific flag
        checkSpecific(){
      
          let allSpecificFlag= []
          let allSpecific = [ 'Pain is aggravated on weight bearing', 'Pain is of sudden onset and is now persistent', 'Bilateral symptoms', 'Constant, severe bony tenderness', 'Swollen, red and warm joint',
             'Pulsating/lump in the groin area','Early morning stiffness over 1hr', 'Sciatica/Lumbar radiculopathy', 'Dysathria`, ‘Drop attack / Faint', 'Significantly reduced cervical AROM', 'Dizziness or/and Dysphagia', 'Diplopia' , 'Positive Lhermitte sign', 'Previous head/neck surgery', 'Localized bony pain at head/neck',
              'Pain is of sudden onset and now persistent',  'Bilateral symptoms', 'Pain started following a long flight','Swollen, tender and warm calf muscle','Severe, constant bony tenderness', 'Swollen, red and warm joints', 'Chest or/and Belly pain', 'Paresthesia down affected arm',
   'Early morning stiffness for over 1hr']
            
           allSpecific.forEach(each=>{
            if (this.collectedData.has(each)){
            allSpecificFlag.push(each)
          } 
      
   
        })

        
        localStorage.setItem('Red', JSON.stringify(allSpecificFlag))
            
        this.collectedData.clear()
         

       

        },

        // gets diagnosis from the symptoms available
         getDiagnosis(objects, X) {

          console.log(Object)
          console.log(X)
          // if user picks more than one symptom, get the diagnosis. else, do not get any diagnosis
          if (this.collectedData.size > 1){
      
           // compare the similarity of the array property of the objects
           let similarities = objects.map(obj => {
            const common = obj.symptoms.filter(value => X.includes(value));
            return common.length / X.length;
          });
          
          // reaarange the diagnosis based on descending closes to the symptoms
          const sortedObjects = objects.slice().sort((a, b) => {
            const indexA = objects.indexOf(a);
            const indexB = objects.indexOf(b);
            return similarities[indexB] - similarities[indexA];
          });

          // sort the similarity  array in descneding order
          similarities = similarities.sort((a,b) =>{
              return b-a
          })
          
          console.log(similarities)
          

          // calculate percentage accuracy of each diagnosis 
          let first_diagnosis = sortedObjects[0]
          let first_diagnosis_score = Math.round(similarities[0] * 100)
           if (first_diagnosis_score < 50){
                first_diagnosis_score = Math.round(first_diagnosis_score + 50)}
           if (first_diagnosis_score >= 70){
               first_diagnosis_score = Math.round(first_diagnosis_score + 10)
          } if (first_diagnosis_score > 90){
              first_diagnosis_score = 90
          } 

          // let second_diagnosis = sortedObjects[1]
      
        
          // return the first two diagnosis and their percentages 
          this.Finaldiagnosis= [
              {'diagnosis': first_diagnosis.name, 
              'percentage': `${first_diagnosis_score}%`,
            id : Math.floor(Math.random * 63738)}, 
             
          ];
          
          localStorage.setItem('Diagnosed_Condition', JSON.stringify({'diagnosis': first_diagnosis.name, 'percentage': first_diagnosis_score}))


          // localStorage.setItem('differential', JSON.stringify({'diagnosis': second_diagnosis.name, 'percentage': second_diagnosis_score}))

       } 
       else {
       
        this.Finaldiagnosis= [
              {'diagnosis': 'You have not reported any symptoms', 
              'percentage': `0%`,
            id : Math.floor(Math.random * 63738)}, 
             
          ];
       }
}

  },


created(){


   // initialized the first symptom page with title
  this.title= body.title[`${this.showMain}`]


 },

 computed:{

  // sort all collected data 
  collectedDataSorted(){
    let Alldata= this.collectedData.values()
 
  let patientData= '' 
  for (const patient of Alldata ){
    patientData= patientData + ' ' +patient
  }

  return patientData

  }
 }



}

</script>


<style scoped>





</style>