import  {createRouter, createWebHistory} from 'vue-router'

import Home from '../views/home'
import symptom from '../views/symptom'
import about from '../views/about'
import blog from '../views/blog'
import download from '../views/download'
import sendEmail  from '../views/sendEmail'
import findaphysio from '../views/findAPhysio'
import signup from '../views/signup'
import gateway from '../views/gateway'
import login from '../views/login'
import profile from '../views/profile'
import privacy from '../views/privacy'
import urgent from '../views/urgent'
import adminlogin from '../views/admin'
import resetPassword from '../views/resetPassword'
// import {store} from '../store/index'



const routes =[

{
    path: '/*',
    redirect:{
        name: 'Home'
    
    }

},

{ 
    path: '/:catchAll(.*)', 
    component: Home,
    name: 'NotFound'
  },

{
    path: '/home',
    name: 'Home',
    component: Home

},
{
    path: '/symptom/:firstname',
    name: 'symptom',
    component: symptom,
    props: true,
    meta:{
        properGateway: true
    }

},
{
    path: '/about',
    name: 'about',
    component: about

},
{
    path: '/profile/:username',
    name: 'Profile',
    component: profile,
    meta: {
        requireAuth: true
    }

},

{
    path: '/blog',
    name: 'blog',
    component: blog

},
{
    path: '/login',
    name: 'login',
    component: login,
    

},

{
    path: '/download-report',
    name: 'download-component',
    component: download,
    props: true,
    meta:{
        properGateway: true
    }

},

{
    path: '/find-a-physio',
    name: 'findaphysio-component',
    component: findaphysio

},
{
    path: '/email-report',
    name: 'send-component',
    component: sendEmail,
    meta:{
        properGateway: true
    }

},
{
    path: '/signup',
    name: 'signup-component',
    component: signup,
    meta:{
        properGateway: false
    }


},

{
    path: '/gateway',
    name: 'gateway-component',
    component: gateway

},
{
    path: '/login/reset-password',
    name: 'reset-component',
    component: resetPassword

},
{
    path: '/privacy',
    name: 'privacy-component',
    component: privacy

},
{
    path: '/urgent',
    name: 'urgent-component',
    component: urgent,
    meta:{
        properGateway: true
    }

},
{
    path: '/admin-login',
    name: 'adminlogin-component',
    component: adminlogin,
   

},






]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})



router.beforeEach((to) => {
   // check if the User has passed through the gateway
    const userRegistered = localStorage.getItem('User');

    //check if User has successfully logged in

    // const savedVuex = JSON.parse(sessionStorage.getItem('vuex')).currentUser
    const authenticatedUser =  JSON.parse(sessionStorage.getItem('vuex'))? JSON.parse(sessionStorage.getItem('vuex')).currentUser.isAuthenticated: false
    // const authenticatedUser = localStorage.getItem('auth')===null? false: true


    // console.log(authenticatedUser)

   
    const requiresGate = to.matched.some(record => record.meta.properGateway)

      //if this page requires gateway, check if it has passed gateway
    if ( requiresGate && !userRegistered ) {
        // alert("You are not authorized to access this area.");
        return ('gateway');
    }
    
    //if this page requires to be logged, check if user is logged in 
    else  if ( to.meta.requireAuth && !authenticatedUser ) {
        return ('login');

    }
   
});





export default router 