let body = {

    'title': {
        0: [`Let's start by selecting a body part!`, `Kindly select the part of the body your symptoms is (One body part at a time).`],
        1:['General Safety (Not related to the affected part)', 'Carefully select from the symptoms below. You will be redirected if you have two of the below symptoms'],
        2:[`Specific Safety (Based on the affected part)`, 'Carefully select the ones that relates with your immediate symptoms'],
        3:['Now, tell me about your Pain', `Click 'next' if you dont have any pain complaints`],
        4:['I need more details on your pain', `Ensure to select the one that fits into your pain description`],
        5:['Couple more questions around movement', `If any of the below options doesnt apply to you,click 'next' `],
        6:['Weldone! This is the last page', `Choose any one that apply to you`],
        7:['Here is my cinical impression', `This is entirely based on the information you have just provided`]
    },   

    'General':{
                'My pain is constant, wakes me up at night or interferes with my sleep': 'Constant night pain',
                'I noticed an unintentional weight loss of over 8kg in the last six months without exercise or change in diet.': 'Unexplained weight loss',
                'I noticed a recent change in my bowel/ bladder habits or/and sexual performance': 'Bladder and Bowel dysfunction',
                'I recently experienced significant trauma, such as a fall or car accident': 'Significant Trauma',
                'I have a history of cancer': 'History of Cancer',
                'I have noticed a recent change in my walking pattern': 'Gait abnormality',
                'I have a fever or/and rash or sore throat and sometimes sweat a lot at night even with windows open': 'Infection or Inflammatory signs such as; Fever and Night sweat',
                'My pain isn’t relieved by medication' : 'Pain isn’t relived by pain medication'},
    
'Head & Neck': { 
     pain: {
        
    'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
    'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
    'My pain feels dull and aching ' : 'Dull and Achy pain',
    'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
    'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
    'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
    'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
    'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
            
     },
     morepain: {
        
    'My pain is in on one side of my neck' : 'One-sided neck pain',
    'My pain along the center of my neck (spine)' : 'Pain along the neck spinous process', 
    'My pain is on the both side neck down to the tip of my shoulder ' : 'Pain on bilateral UFT',
    'My pain comes and goes (intermittent)' : 'Intermittent pain',
    'I don’t feel the pain until I touch it' :'Pain is aggravated on palpation',
    'My pain is worse when I cough or sneeze' : 'Pain is aggravated by cough or sneeze', 
    'I have pain down one arm from the neck' : 'Radicular pain radiating down one arm'
     },
     Rom: {
        

    'My pain is worse when I keep my neck in one position' : 'Neck pain is aggravated when the neck is kept in a single position',
    'Lifting a shopping bag makes shoulder/shoulders ache' : 'Shoulder/UFT aches when lifting things with affected hand', 
    'My pain is worse I turn my neck around' : 'Pain is aggravated by neck rotation',
    'My pain is relieved when I stop moving my neck around' : 'Pain is relieved by rest',
    'Bending my neck forward decreases my neck pain ' : 'Pain is eased by neck flexion',
    'Bending my neck forward increases my neck pain ' :'Pain is aggravated by neck flexion',
    'My pain is worsened when I chew and/or yawn' : 'Pain aggravated by chewing and/or yawning',
    'Bending my neck backward increases my neck pain ' : 'Pain is aggravated by neck extension',
   
     },
    Others:{
    'My neck pain often comes with one sided headache' : 'Cervicogenic headache',
    'My pain is over my jaw':'Pain over TMJ',
    'I recently started doing upper back or/and trap exercises at the gym' : 'Recently started upper back exercises at the gym', 
    'My neck feels like it need to clicked' : 'Movement deficit in the cervical spine',
    'My neck pain started after my car was hit by another car' : 'Neck pain post road traffic accident',
    'I am over 50 years old': 'Over 50 years',
    'I take over 15 bottles of alcohol weekly': 'Takes over 15 bottles of alcohol',

    },
     flag: {
'I have been experiencing difficulty articulating my speech (slurred)' : `Dysathria`, 
'I have experienced sudden fall/falls recently and sometimes lose consciousness' : 'Drop attack / Faint',
'I can only rotate my neck a bit in either direction': 'Significantly reduced cervical AROM',
'I have been experiencing dizziness or/and difficulty swallowing' : 'Dizziness or/and Dysphagia',
'Been experiencing visual disturbance sometimes double vision' :'Diplopia' ,
'I get pain/shock down my body, arms and leg when I bend my neck forward' : 'Positive Lhermitte sign',
'I have had injuries or surgeries to the affected area in the past':  'Previous head/neck surgery',
'A single spot on my neck is painful on touch, feels deep, like over a bone': 'Localized bony pain at head/neck'

     }   
    },
 'Hip':{ 
    flag: {
        'My pain is increased when I rest my body weight on my leg  (can’t bear weight on my leg)': 'Pain is aggravated on weight bearing',
        'My pain started  suddenly and is now  persistent' : 'Pain is of sudden onset and is now persistent',
        'I am experiencing similar symptoms on both limbs' : 'Bilateral symptoms', 
        'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone': 'Constant, severe bony tenderness',
        'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joint',
        'I can feel a pulse or lump in my groin ': 'Pulsating/lump in the groin area',
        'My joint feels stiff/stuck every morning for over 1 hour': 'Early morning stiffness over 1hr',
        'I am experiencing pins and needles or numbness down my legs (dead leg)' : 'Sciatica/Lumbar radiculopathy'
            },
            pain: {
        
                'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
                'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
                'My pain feels dull and aching ' : 'Dull and Achy pain',
                'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
                'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
                'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
                'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                        
                 },
                    morepain: {
                        'My pain is in front of the hip' : 'Anterior hip pain',
                        'My pain is close to my groin' : 'Groin pain', 
                        'My pain is on the outside area of my hip' : 'Lateral hip pain',
                        'My pain is around my buttock area' : 'Gluteal pain',
                        'My pain is on both side of my buttock' :'Bilateral gluteal pain',
                        'My pain doesn’t  have a particular spot (I can’t pinpoint the affected area)' : 'Diffuse hip pain', 
                        'My pain is relieved after eating a meal' : 'Pain is eased post meal intake',
                        'My pain is made worse when I use the toilet' : 'Pain is aggravated post bowel/bladder usage'
                    },
                    Rom: {
                        'My pain is worse when I stand or walk for long' : 'Pain is aggravated by prolong standing or/and walking',
                        'My hip joint feels restricted and stiff, particularly after sitting or resting for a while ' : 'Gelling at the hip', 
                        'I feel a crunching sound when I walk or move my hip joint around' : 'Hip joint crepitus ',
                        'My hip is often stiff for about an hour first thing in the morning' : 'Early mornign stiffness at the hip',
                        'My hip pain is worse when I bend my low back backwards' :'Hip pain is aggravated by back extension',
                        'My hip pain is relieved when I stop walking around' : 'Hip pain is relieved with rest', 
                        'My hip pain in made worse when climbing up the stairs or down the stairs' : 'Hip pain is aggravated with stairs climbing',
                        '7-My hip pain makes me limp when I walk' : 'Antalgic gait on the affected leg'
                    },
                    Others:{
                        'My hip  joint feels like it’s giving way' : 'Giving way feeling at the hip joint',
                        'My hip joint feels unstable' : 'Hip joint feels unstable', 
                        'The pain stops me from engaging in physical activities' : 'Reduced physical functioning',
                        '4-Standing on affected leg for 30 sec increases my hip pain ' : 'Positive single leg stance test',
                        'I am over 50 years old': 'Over 50 years',
                        'I take over 15 bottles of alcohol weekly': 'Takes over 15 bottles of alcohol',
                        'This started after an injury on the same leg': 'Traumatic history on symptomatic leg',
                        'I recently started doing glute exercises at the gym' : 'Recently started glute exercises at the gym', 

                    },
       },

'Glute': {
    flag: {
        'My pain is increased when I rest my body weight on my leg  (can’t bear weight on my leg)': 'Pain is aggravated on weight bearing',
        'My pain started  suddenly and is now  persistent' : 'Pain is of sudden onset and is now persistent',
        'I am experiencing similar symptoms on both limbs' : 'Bilateral symptoms', 
        'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone': 'Constant, severe bony tenderness',
        'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joint',
        'I can feel a pulse or lump in my groin ': 'Pulsating/lump in the groin area',
        'My joint feels stiff/stuck every morning for over 1 hour': 'Early morning stiffness over 1hr',
        'I am experiencing pins and needles or numbness down my legs (dead leg)' : 'Sciatica/Lumbar radiculopathy'
            },
            pain: {
        
                'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
                'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
                'My pain feels dull and aching ' : 'Dull and Achy pain',
                'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
                'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
                'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
                'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                        
                 },
                morepain: {
                    'My pain is in front of the hip' : 'Anterior hip pain',
                    'My pain is close to my groin' : 'Groin pain', 
                    'My pain is on the outside area of my hip' : 'Lateral hip pain',
                    'My pain is around my buttock area' : 'Gluteal pain',
                    'My pain is on both side of my buttock' :'Bilateral gluteal pain',
                    'My pain doesn’t  have a particular spot (I can’t pinpoint the affected area)' : 'Diffuse hip pain', 
                    'My pain is relieved after eating a meal' : 'Pain is eased post meal intake',
                    'My pain is made worse when I use the toilet' : 'Pain is aggravated post bowel/bladder usage'
                },
            Rom: {
                'My gluteal pain is worse when I sit for a long period' : 'Gluteal pain aggravated by prolong sitting',
                'Bending to pick an object from the floor increases my pain' : 'Gluteal pain aggravated by lumbar forward flexion ', 
                'My gluteal pain is worse when I raise my leg straight while lying down ' : 'Gluteal pain aggravated with straight leg raise (SLR)',
                'My gluteal pain is worse when I stand or walk a long distance' : 'Gluteal pain aggravated with prolong standing/walking',
                'My hip joint feels restricted and stiff, particularly after sitting or resting for a while ' :'Gelling at the hip joint',
                'My gluteal pain is relieved when I stop walking around' : 'Gluteal pain eased with rest', 
                'My gluteal pain in made worse when climbing up the stairs or down the stairs' : 'Gluteal pain aggravated by stair climbing',
                'My gluteal pain isn’t affected by prolong standing/walking ' : 'Gluteal pain is not aggravated by prolong standing/walking '
            },
            Others:{
                'I have pins and needles down one leg ' : 'Radicular symptoms down on leg',
                'I recently started doing glute exercises at the gym' : 'Recently started gluteal exercises', 
                'The pain stops me from engaging in physical activities' : 'Gluteal pain limits physical functioning',
                'Standing on affected leg of 30 sec increases my pain' : 'Positive single leg stance test',
                'My pain gets worse when I touch the affected area' : 'Pain aggravated by palpation',
            },
        },
 'Thigh':{
           flag: {
        'My pain is increased when I rest my body weight on my leg  (can’t bear weight on my leg)': 'Pain is aggravated on weight bearing',
        'My pain started  suddenly and is now  persistent' : 'Pain is of sudden onset and is now persistent',
        'I am experiencing similar symptoms on both limbs' : 'Bilateral symptoms', 
        'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone': 'Constant, severe bony tenderness',
        'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joint',
        'I can feel a pulse or lump in my groin ': 'Pulsating/lump in the groin area',
        'My joint feels stiff/stuck every morning for over 1 hour': 'Early morning stiffness over 1hr',
        'I am experiencing pins and needles or numbness down my legs (dead leg)' : 'Sciatica/Lumbar radiculopathy'
            },
            pain: {
        
                'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
                'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
                'My pain feels dull and aching ' : 'Dull and Achy pain',
                'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
                'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
                'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
                'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                        
                 },
            morepain: {
                'My pain is in front of the thigh' : 'Anterior thigh pain',
                'My pain is close to my groin (inner thigh area)' : 'Groin pain', 
                'My pain is on the outside area of my thigh' : 'Lateral thigh pain',
                'My pain is behind my thigh ' : 'Posterior thigh pain',
                'I have  pain is on both thighs' :'Bilateral thigh pain',
                'I can feel something pulsating (breathing) in the affected area' : 'Pulsating area on the thigh', 
                'My pain feels throbbing ' : 'Throbbing thigh pain',
                'My pain is made worse when I use the toilet' : 'Thigh pain is worsened post bladder/bowel usuage'
            },
            Rom: {
                'My pain is worse when I sit for a long period' : 'Thigh pain aggravated by prolong sitting',
                'Bending to pick an object from the floor increases my pain' : 'Thigh pain aggravated by lumbar forward flexion', 
                'My pain is worse when I stand or walk a long distance' : 'Thigh pain aggravated with prolong standing/walking',
                'My pain runs down my thigh from the hip to the knee' : 'pain runs down from the hip to the knee',
                'My pain is relieved when I stop walking around' :'Thigh pain eased with rest',
                'My  pain in made worse when climbing up the stairs or down the stairs' : 'Thigh pain aggravated by stair climbing', 
                'My pain isn’t affected by prolong standing and walking ' : 'Thigh pain is not aggravated by prolong standing/walking',
                'My pain is less if I move the affected leg with the unaffected leg' : 'Passive movement does not aggravate pain'
           
            },
            Others:{
                'My hamstring feels tight ': 'Hamstring tightness',
                'The front of my thigh feels tight ': 'Quadriceps tightness',
                'I think I pulled a muscle ' : 'Feels like a pulled muscle', 
                'My pain gets worse when I touch the affected area' : 'Pain aggravated by palpation',
                'I cannot reach my toes with my fingers (without bending my knee)' : 'Tight hamstrings',
                'I play contact sport' : 'Sport-related muscle pathology',

            },
        },

'Knee':{
        flag: {
            'My pain is increased when I rest my body weight on my leg  (can’t bear weight on my leg)': 'Pain is aggravated on weight bearing',
            'My pain started  suddenly and is now  persistent' : 'Pain is of sudden onset and is now persistent',
            'I am experiencing similar symptoms on both limbs' : 'Bilateral symptoms', 
            'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone': 'Constant and severe bony tenderness',
            'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joint',
            'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
            'My joint feels stiff/stuck every morning for over 1 hour': 'Early morning stiffness over 1hr',
            'I am experiencing pins and needles or numbness down my legs (dead leg)' : 'Sciatica/Lumbar radiculopathy'
                },
                pain: {
        
                    'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
                    'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
                    'My pain feels dull and aching ' : 'Dull and Achy pain',
                    'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
                    'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
                    'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
                    'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                    'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                            
                     },
                morepain: {
                    'My pain is in front of the knee' : 'Anterior knee pain',
                    'My pain is behind my knee joint' : 'Posterior knee pain', 
                    'My pain is on the outside area of my knee ( that leads down to little toe)' : 'Lateral Knee pain',
                    'My pain is on the inside area of my knee (that leads down to my big toe)' : 'Medial knee pain',
                    'My pain is under my knee cap' : 'Retropatella pain', 
                    'My pain is just below my knee joint' : ' Superior tibia pain',
                    'My pain is just below my knee cap' : 'Inferior knee pain',
                    'My pain is just above my knee cap' : 'Superior knee pain'
                },
                Rom: {
                 
                   
                    'I feel a crunching/clicking sound when I walk or move my knee around' : 'Crepitus/clicking at knee joint',
                    'My Knee pain is worse when I jump and land on affected leg' : 'Positive single leg hop test ',
                    'My knee  pain isn’t affected by prolong standing/walking ' : 'Knee pain is not aggravated by prolong standing/walking ',
                    'My Knee pain is worse when I sit for a long period' : 'Knee pain aggravated by prolong sitting',
                    'My knee pain is worse when I stand or walk a long distance' : 'Knee pain aggravated with prolong standing/walking',
                    'My knee joint feels restricted and stiff, particularly after sitting or resting for a while ' :'Gelling at the Knee joint',
                    'My knee pain is relieved when I stop walking around' : 'Knee pain eased with rest', 
                    'My knee pain in made worse when climbing up the stairs or down the stairs' : 'Knee pain aggravated by stair climbing',
                   
                },
            
            Others:{
                'My knee feels like it’s giving way' : 'Knee joint feels like it is giving way',
                'My pain gets worse when I touch the affected area' : 'Pain aggravated by palpation', 
                'I have been cycling for a while now': 'Cyclist',
                'I am over 50 years old': 'Over 50 years',
                'I take over 15 bottles of alcohol weekly': 'Takes over 15 bottles of alcohol',
                'I fell and landed on a twisted leg' : 'Fell and landed on a twisted leg',
                'My kneecap feels unstable when I move it' : 'Patella unstable on movement'

            },
              

        },

'Leg':{

            flag: {
                'My pain is increased when I rest my body weight on my leg  (can’t bear weight on my leg)': 'Pain is aggravated on weight bearing',
                'My pain started  suddenly and is now  persistent' : 'Pain is of sudden onset and is now persistent',
                'I am experiencing similar symptoms on both limbs' : 'Bilateral symptoms', 
                'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone': 'Constant, severe bony tenderness',
                'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joint',
                'The pain started after a long flight ' : 'Pain started following a long flight',
                'My calf is swollen, tender and warm ' :'Swollen, tender and warm calf muscle',
                'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
            },
            pain: {
        
                'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
                'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
                'My pain feels dull and aching ' : 'Dull and Achy pain',
                'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
                'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
                'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
                'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                        
                 },
            morepain: {
                'My leg pain is in front of the leg' : 'Anterior leg pain',
                'My leg pain is just below my knee' : 'Posterior knee pain', 
                'My leg pain is on the outside area of my leg (that leads down to my big toe)' : 'Medial leg pain',
                'My leg pain is behind my leg' : 'Posterior leg pain',
                'My leg pain is on both legs' :'Bilateral Leg pain',
                'I can feel something pulsating (breathing) in the affected area' : 'Pulsating area in the leg', 
                'My leg pain is over my calf muscle' : 'Calf pain',
                'My leg pain doesn’t  have a particular spot (I cant pinpoint the affected area)': 'Diffuse leg pain'
            },
            Rom: {
              
                'My pain is worse when I walk on my toes' : 'Leg pain aggravated by toe walking',
                'My pain is worse when I walk on my heels' : 'Leg pain aggravated by heel walking',
                'Bending to pick an object from the floor increases my pain' : 'Leg pain aggravated by lumbar forward flexion', 
                'My pain is worse when I stand or walk a long distance' : 'Leg pain aggravated with prolong standing/walking',
                'My pain runs down my leg from the knee to the foot' : 'Leg pain runs down from the knee to the foot',
                'My pain is relieved when I stop walking around' :'Leg pain eased with rest',
                'My  pain in made worse when climbing up the stairs or down the stairs' : 'Leg pain aggravated by stair climbing', 
                'My pain isn’t affected by prolong standing and walking ' : 'Leg pain is not aggravated by prolong standing/walking',
            
            },
            Others:{
                'My calf feels tight ' : 'Calf muscle feels tight',
                'I am currently taking an anticoagulant' : 'Presently taking anticoagulant', 
                'The pain started after a long flight ' : 'Pain started following a long flight',
                'My calf is swollen, tender and warm ' :'Swollen, tender and warm calf muscle',
               
            
            },
        },

'Ankle & Foot':{
    flag: {
        'My pain is increased when I rest my body weight on my leg  (can’t bear weight on my leg)': 'Pain is aggravated on weight bearing',
        'My pain started  suddenly and is now  persistent' : 'Pain is of sudden onset and is now persistent',
        'I am experiencing similar symptoms on both limbs' : 'Bilateral symptoms', 
        'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone': 'Constant, severe bony tenderness',
        'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joint',
        'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
        'My joint feels stiff/stuck every morning for over 1 hour': 'Early morning stiffness over 1hr',
        'I am experiencing pins and needles or numbness down my legs (dead leg)' : 'Sciatica/Lumbar radiculopathy'
            },
            pain: {
        
                'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
                'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
                'My pain feels dull and aching ' : 'Dull and Achy pain',
                'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
                'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
                'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
                'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                        
                 },
            morepain: {
                'My sole pain increases when walking barefoot on hard surfaces ' : 'Plantar  pain aggravated by walking barefooted on hard surfaces',
                'Pain gets worse when I bend your toes downward (squeeze)' : 'Pain worse with toes flexion', 
                'My pain is in front of the ankle' : 'Anterior ankle pain',
                'My pain is on my heel' : 'Posterior ankle pain',
                'My pain is on the sole of my foot' :'Pain on plantar surface',
                'My pain is on just below my ankle, right on my foot ' : 'Proximal dorsal foot pain', 
                'My pain is on my big toe ' : 'Big toe pain',
                'My pain doesn’t  have a particular spot (I cant pinpoint the affected area)' : 'Diffuse pain'
            },
            Rom: {
                'My pain is worse when I stand or walk' : 'Pain is aggravated with standing or walking',
                'My  joint feels restricted and stiff, particularly after sitting or resting for a while ' : 'Gelling at the ankle joint,', 
                'I feel a crunching sound when I walk or move my joint around' : 'Crepitus at my ankle Joint',
                'My pain is worse when I jump and land on affected leg' : 'Positive single leg hop test',
                'My pain is the same when someone else moves my ankle' :'Pain isn’t aggravated with passive movement',
                'My pain is relieved when I stop walking around' : 'Pain is relieved with rest', 
                'My ankle is often stiff for about an hour first thing in the morning' : 'Early morning stiffness at the ankle for about an hour',
                'My foot is often stiff for over 1 hour first thing in the morning' : 'Early morning stiffness at the ankle for over 1 hour'
            },
            Others:{
                'My ankle joint feels like it’s giving way' : 'Ankle joint feels giving way',
                'My ankle joint feels unstable ' : 'Ankle Joint feels Unstable', 
                'My sole aches first thing in the morning ' : 'Early morning pain on platar foot surface',
                'Sharp pain on a single bony area in my foot when I jump and land on it' : 'Positive single leg hop test on foot',
                'Pain started when I landed with my ankles/foot turned  inwards' :'Landed on inverted foot',
                'Pain started when I landed with my ankles/foot turned  outwards' : 'Landed on everted foot', 
                'I have a flat foot' : 'Flat foot',
                'My big toe is hot and swollen' : 'Swollen and hot big toe'
            },
        },
       
'Back':{
    pain: {
        
        'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
        'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
        'My pain feels dull and aching ' : 'Dull and Achy pain',
        'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
        'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
        'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
        'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
        'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                
         },
        morepain: {
            'My back pain is in on one side of my back' : 'Unilateral back pain',
            'My back pain at the center of my back (spine)' : 'Mid-line spinal pain', 
            'My back pain is on the both side of my low back/buttock are' : 'Bilateral sacral pain',
            'My pain comes and goes  (intermittent)' : 'Intermittent back pain',
            'I don’t feel the pain until I touch it ' :'Back pain is aggravated on palpation',
            'My pain is worse when I cough or sneeze' : 'Back pain is aggravated with cough/sneeze', 
            'My back pain is relieved after eating a meal' : 'Back pain is relieved after a meal ',
            'My back pain is made worse when I use the toilet' : 'Back pain is aggravated post bladder/bowel usuage'
        },
        Rom: {
            'My back pain is worse when I sit for a long period' : 'Back pain is aggravated by prolong sitting',
            'Bending to pick an object from the floor increases my pain' : 'Back pain is aggravated by lumbar flexion', 
            'My pain is worse when I stand or walk a long distance' : 'Back pain is aggravated by prolong standing/walking',
            'My pain is relieved when I stop walking around' : 'Back pain is relived by rest',
            'Bending forward increases my back pain ' :'Back pain is aggravated by lumbar flexion',
            'Bending forward relieves my back pain ' : 'Back pain is eased by lumbar flexion', 
            'Bending backward relieves my back pain ' : 'Back pain is eased by lumbar extension',
            'My pain isn’t affected by prolong standing and walking ' : 'Back pain is unaffected by prolong standing/walking'
        },
        Others:{
            'I have pins and needles down my leg/legs' : 'Lumbar radiculopathy/ Sciatica',
            'I recently started doing back exercises at the gym' : 'Recently started back exercises', 
            'I have pain across my whole back' : 'Pain across the whole back',
            'My back feels like it need to clicked' : 'Mobility deficit at the back',
            'I am over 50 years old': 'Over 50 years',
            'I take over 15 bottles of alcohol weekly': 'Takes over 15 bottles of alcohol',
            
          
        },
        flag: {
            'I have been experiencing persistent cough sometimes with or without red-stained sputum' : 'Persistent cough with/without blood-stained sputum',
            'My back pain is not relieved when I lie down to rest': 'Back pain is not relieved by rest ',
            'I feel reduced sensation around my inner thigh and buttock area': 'Saddle anesthesia',
            'A single spot on my back is painful on touch, feels deep, like over a bone': 'Bony tenderness on spinal bone',
            'I feel like I have lost control of my legs/balance': 'Loss of balance',
            'My pain is  10/10,  sharp and is always there (constant)': 'Severe, sharp and constant back pain',
            'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening', 
            'My back gets stiff every morning for over 1 hours': 'Early morning back stiffness' 
                        
        } 
    },
'Chest':{
    pain: {
        
        'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
        'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
        'My pain feels dull and aching ' : 'Dull and Achy pain',
        'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
        'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
        'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
        'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
        'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                
         },
        morepain: {
            'My pain is on my collar bone' : 'Pain on the clavicle',
            'I have pain on one side of my chest' : 'One-sided chest pain', 
            'I have a sharp pain at the center of my chest (on a bone)' : 'Sternal pain',
            'My pain is below my collar bone' : 'Pectoralis pain',
            'My chest pain doesn’t  have a particular spot (I cant pinpoint the affected area)' :'Diffuse chest pain',
            'My chest  pain is worsened with deep inspiration' : 'Chest pain is aggravated by inspiration', 
            'My chest pain is worsened whenever I cough only ' : 'Chest pain is aggravated by cough',
            'My chest pain comes with pain down my left arm ' : 'Chest pain with left arm pain'
        },
        Rom: {
            'I cannot move my shoulder/shoulders fully in all directions' : 'Limited shoulder AROM in all directions ',
            'Any movement of my shoulder produce cracking or pop sound at the upper chest' : 'Crepitus at the stenoclavicular joint', 
            ' Touching the unaffected shoulder  increases pain at the tip of the affected shoulder' : 'Posistive cross adduction test',
            'My arm feels more  weak than painful while moving it' : 'Shoulder feels more weak than painful',
            'My chest joints feels restricted and stiff' :'Stiff Stenoclavicular or/and Stenocostal joint',
            'My pain is relieved when I am not moving my shoulders' : 'Chest pain is relieved with rest', 
            'My pain is not relieved even when I stop moving my shoulders' : 'Chest is not relieved with rest',
            'My pain is worse when I lift something heavy in my hands ' : 'Chest pain is aggravated when lifting heavy objects'
        },
        Others:{
            'I’m taking angina medication' : 'Currently taking angina medications',
            'I have a heart condition ' : 'Diagnosed with a heart condition', 
            'My chest pain started after a physical activity (e.g; running, football)' : 'Chest pain started following a physical activity',
            'I have a cough ' :'Have a cough',
            'I often get chest pain (particularly my left chest area)' : 'Chronic left chest pain', 
           
        },
        flag: {
        
    'My chest pain developed suddenly and now persistent' : 'Chest pain developed suddenly and now persistent', 
    'I feel breatheless sometimes' : 'Breathlessness',
    'My heart runs faster than usual' : 'Bradycardia',
    'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                  
            }   
    },

 'Shoulder':{
    flag: {
                
        'My pain is sudden and persistent' : 'Pain is of sudden onset and now persistent', 
        'I am experiencing the same symptom on both arms' : 'Bilateral symptoms',
        'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
        'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone' : 'Severe, constant bony tenderness',
        'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joints',
        'I am experiencing pain in my chest or/and my belly' : 'Chest or/and Belly pain', 
        'I am experiencing pins and needles or numbness down my arms (dead arm)' : 'Paresthesia down affected arm',
        'My joint feels stiff/stuck every morning for over 1 hour' : 'Early morning stiffness for over 1hr'

        },  
        pain: {
        
            'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
            'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
            'My pain feels dull and aching ' : 'Dull and Achy pain',
            'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
            'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
            'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
            'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
            'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                    
             },
                morepain: {
                    'My pain is at the tip of my shoulder ' : 'Pain at tip of the shoulder',
                    'My pain in front of my shoulder ' : 'Anterior shoulder pain', 
                    'My pain is behind my shoulder ' : 'Posterior shoulder pain',
                    'My pain is on the side of my shoulder (where I lay on)' : 'Lateral shoulder pain',
                    'My pain doesn’t  have a particular spot (I cant pinpoint the affected area)' : 'Diffuse shoulder pain',
                    'My pain is deep into my shoulder joint' : 'Deep shoulder pain', 
                    'My pain is relieved after eating a meal' : 'Pain is relieved after a meal',
                    'My shoulder pain is worse when I bend my neck forward' : 'Shoulder pain is aggravated with neck flexion '
                },
                Rom: {
                    'I cannot move my shoulder  fully in all directions' : 'Limited shoulder AROM in all direction',
                    'Any movement of my shoulder produce Popping/clicking/cracking/grinding sound' : 'Popping, clicking, or catching sensation in the shoulder', 
                    'Any movement of my shoulder over my head produce sharp and stabbing pain' : 'Overhead shoulder movement aggravate symptom',
                    'My sholder feels more  weak than painful while moving it' : 'Weaker Shoulder',
                    'My shoulder joint feels restricted and stiff' :'Shoulder joint feels restricted and stiff',
                    'My pain is relieved when I stop moving my arm' : 'Shoulder pain eased by rest', 
                    'My pain is not relieved even when I stop moving my arm ' : 'Shoulder pain not eased by rest',
                    'My  pain in made worse when I lie on the affected arm' : 'Shoulder pain is aggravated when laid upon'
                },
                Others:{
                    'My shoulder feels like it’s giving way' : 'Shoulder gives way',
                    'My shoulder feels unstable ' : 'Shoulder feels Unstable', 
                    'I find overhead activities difficult i.e Brushing my hair' : 'Difficulty with overhead movement',
                    'I play badminton, long tennis, or other sports that requires lifting my arm over my head' : 'Engage in sports with overhead movement',
                    'I recently started a new sport or just started working out' :'Recently started working out',
                    ' Resting my body weight fully on my palm (with my )makes my pain worse' : 'Pain with weightbearing on arm', 
                    'I am over 50 years old': 'Over 50 years',
                'Shoulder (front) pain that somtimes shoots down my arm': 'Frontal shoulder pain that may radiate down the arm',
                },
            },
'Arm':{
                flag: {
                
                    'My pain is sudden and persistent' : 'Pain is of sudden onset and now persistent', 
                    'I am experiencing the same symptom on both arms' : 'Bilateral symptoms',
                    'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                    'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone' : 'Severe, constant bony tenderness',
                    'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joints',
                    'I am experiencing pain in my chest or/and my belly' : 'Chest or/and Belly pain', 
                    'I am experiencing pins and needles or numbness down my arms (dead arm)' : 'Paresthesia down affected arm',
                    'My joint feels stiff/stuck every morning for over 1 hour' : 'Early morning stiffness for over 1hr'
            
                    },  
                       
                    pain: {
        
                        'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
                        'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
                        'My pain feels dull and aching ' : 'Dull and Achy pain',
                        'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
                        'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
                        'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
                        'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                        'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                                
                         },
                morepain: {
                    'My pain is in front of the arm (if I turn my palm forward)' : 'Anterior arm pain',
                    'My pain is just before my elbow' : 'Pain proximal to elbow joint', 
                    'My pain is just below my shoulder' : 'Proximal arm pain',
                    'My pain is behind my arm  (if I turn my palm forward)' : 'Posterior arm pain',
                    'My pain is on both arm' :'Bilateral arm pain',
                    'I can feel something pulsating (breathing) in the affected area' : 'Pulsating area on the arm', 
                    'My pain is over my bicep muscle' : 'Pain over my Bicep muscle',
                    'My pain is made worse when I bend my neck ' : 'Arm pain aggravated by neck flexion'
                },
                Rom: {
                    'I cannot lift my arm fully in all directions' : 'Limted AROM on the affected arm',
                    'I have bruising over the affected area' : 'Bruising on the affected arm', 
                    'Any movement of my arm over my head produce sharp and stabbing pain' : 'Arm pain aggravated by overhead movement',
                    'I cannot rest my body weight on my palm/hand  with my arm kept straight ' : 'Inability to put body weight on affected arm',
                    'My pain is relieved when I stop moving my arm' :'Arm pain relieved with rest',
                    'My pain is not relieved even when I stop moving my arm ' : 'Arm pain not relieved with rest', 
                    'My pain in made worse when I lie on the affected arm' : 'Arm pain worsened if lied uopn',
                    'The pain is not worsened if I lift the affected arm with the unaffected arm' : 'Passive movement does not aggaravate arm pain'
                },
                Others:{
                    'I just started working out my arms at the gym (or recently increased your weight) ' : 'Just started arm workouts/ Increased gym weights',
                    'I am currently taking an anticoagulant' : 'Currently taking anti-coagulants', 
                    'The pain started after removal of brace/POP/cast' : 'Arm pain following a long period of immobility',
                    'I think I pulled a muscle' : 'Feels like a pulled muscle',
                    'I feel shock/numbness/pins running down my arm into my fingers' :'Paresthesia down on affected arm',
                
                },
            },
'Elbow':{
    flag: {
                
        'My pain is sudden and persistent' : 'Pain is of sudden onset and now persistent', 
        'I am experiencing the same symptom on both arms' : 'Bilateral symptoms',
        'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
        'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone' : 'Severe, constant bony tenderness',
        'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joints',
        'I am experiencing pain in my chest or/and my belly' : 'Chest or/and Belly pain', 
        'I am experiencing pins and needles or numbness down my arms (dead arm)' : 'Paresthesia down affected arm',
        'My joint feels stiff/stuck every morning for over 1 hour' : 'Early morning stiffness for over 1hr'

        },  
           
        pain: {
        
            'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
            'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
            'My pain feels dull and aching ' : 'Dull and Achy pain',
            'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
            'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
            'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
            'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
            'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                    
             },
                morepain: {
                    'My pain is at the middle of my elbow ' : 'Mid-elbow pain',
                    'My pain in inside of my elbow (on the side that leads to my pinky finger)' : 'Medial elbow pain', 
                    'My pain in outside of my elbow (on the side that leads to my thumb)' : 'Lateral elbow pain',
                    'My pain is on the bone behind my elbow ' : 'Posterior elbow pain',
                    'The bone area behind my elbow is swollen' :'Swollen olecranon',
                    'My pain doesn’t  have a particular spot (I cant pinpoint the affected area)' : 'Diffuse elbow pain', 
                    'My pain started after I feel and landed on my arm ' : 'Elbow pain started after a fall on affected arm',
                    'Any movement of my elbow over my head produce sharp and stabbing pain' : 'Overhead movement of affected arm aggravate elbow pain',
                   
                },
                Others: {
                    'My joint feels like it’s giving way' : 'Elbow feels like it is giving way',
                    'My joint feels unstable' : 'Elbow joint feels unstable', 
                    'I find overhead activities difficult i.e Brushing my hair' : 'Difficulty with overhead activity on affected arm',
                    'I play badminton, long tennis, or other sports that requires lifting my arm over my head' : 'Engage in overhead sport (badminton, long tennis)',
                    'Resting on my palm, while keeping affected elbow straight makes my pain worse ' : 'Pain with weight bearing on affected elbow',
                    'I play golf frequently ' : 'Plays golf often',
                    'I am over 50 years old': 'Over 50 years',
                    'Difficulty with gripping with my hands on the same side':'Hand weakness/Difficulty with gripping and fine motor movements',
                    'My ring and little finger feels numb/ tingles ':'Numbness and tingling in ring and little fingers'
                   
              },
                Rom:{
                    'I cannot move my elbow fully in all directions' : 'Limited multidirectional AROM at elbow joint',
                    'Any movement of my elbow produce cracking or pop sound' : 'Crepitus at affected elbow joint', 
                   'My elbow/arm feels weaker' : 'Affected elbow/arm feels weaker',
                    'My elbow joint feels restricted and stiff' :'Stiff and restricted elbow joint',
                    'My pain is relieved when I stop moving my arm/elbow' : 'Elbow pain is relieved by rest', 
                    'My pain is not relieved even when I stop moving my arm/elbow ' : 'Elbow pain not relieved by rest',
                    'My pain is worse when I grip and  bend my wrist, pointing up' : 'Pain worsens with gripping and wrist extension',
                    'My pain is worse when I grip and bend my wrist, pointing down': 'Pain worsens with gripping and wrist flexion',
                },
            },
'Forearm':{
    flag: {
                
        'My pain is sudden and persistent' : 'Pain is of sudden onset and now persistent', 
        'I am experiencing the same symptom on both arms' : 'Bilateral symptoms',
        'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
        'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone' : 'Severe, constant bony tenderness',
        'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joints',
        'I am experiencing pain in my chest or/and my belly' : 'Chest or/and Belly pain', 
        'I am experiencing pins and needles or numbness down my arms (dead arm)' : 'Paresthesia down affected arm',
        'My joint feels stiff/stuck every morning for over 1 hour' : 'Early morning stiffness for over 1hr'

        },  
        pain: {
        
            'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
            'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
            'My pain feels dull and aching ' : 'Dull and Achy pain',
            'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
            'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
            'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
            'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
            'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                    
             },

                morepain: {
                    'My pain is in front of my forearm (palm facing front)' : 'Anterior forearm pain',
                    'My pain is just before my wrist' : 'Distal forearm pain', 
                    'My pain is just below my elbow' : 'Proximal forearm pain',
                    'My pain is behind my forearm palp facing front)' : 'Posterior forearm pain',
                    'pain is on both forearms' :'Bilateral forearm pain',
                    'I can feel something pulsating (breathing) in the affected area' : 'Pulsating area on affected forearm', 
                    'My pain is on the side that goes down to my thumb' : 'Radial-side forearm pain',
                    'My pain is on the side that goes down to my little finger' : 'Ulna-side forearm pain'
                },
                Rom: {
                   

                    'I cannot lift my arm fully in all directions' : 'Limited AROM on the affected arm',
                    'I have bruising over the affected area' : 'Bruising on the affected forearm', 
                    'Any movement of my wrist reproduce my pain along my forearm' : 'Forearm pain aggravated by wrist movement',
                    'I cannot rest my body weight on my palm/hand  with my arm kept straight ' : 'Inability to put body weight on affected arm',
                    'My pain is relieved when I stop moving my forearm' :'Forearm pain relieved with rest',
                    'My pain is not relieved even when I stop moving my forearm ' : 'Forearm pain not relieved with rest', 
                    'My pain in made worse when I lie on the affected forearm' : 'Forearm pain worsened if laid uopn',
                    'The pain is not worsened if I lift the affected arm with the unaffected arm' : 'Passive movement does not aggaravate arm pain'
                },
                Others:{
                    'I just started working out my arms at the gym (or recently increased your weight) ' : 'Just started arm workouts/ Increased gym weights',
                    'I am currently taking an anticoagulant' : 'Currently taking anti-coagulants', 
                    'The pain started after removal of brace/POP/cast' : 'Forearm pain following a long period of immobility',
                    'I think I pulled a muscle' : 'Feels like a pulled muscle',
                    'I feel shock/numbness/pins running down my forearm into my fingers' :'Paresthesia on affected arm',
                
                },
            },
'Wrist':{
    flag: {
                
        'My pain is sudden and persistent' : 'Pain is of sudden onset and now persistent', 
        'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
        'I am experiencing the same symptom on both arms' : 'Bilateral symptoms',
        'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone' : 'Severe, constant bony tenderness',
        'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joints',
        'I am experiencing pain in my chest or/and my belly' : 'Chest or/and Belly pain', 
        'I am experiencing pins and needles or numbness down my arms (dead arm)' : 'Paresthesia down affected arm',
        'My joint feels stiff/stuck every morning for over 1 hour' : 'Early morning stiffness for over 1hr'

        },  
           
               pain: {
        
    'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
    'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
    'My pain feels dull and aching ' : 'Dull and Achy pain',
    'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
    'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
    'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
    'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
    'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
            
     },
                morepain: {
                    '.My pain is at the middle of my wrist' : 'Middle wrist pain',
                    'My pain in on the inside of my wrist (on the side that leads to my pinky finger)' : 'Medial wrist pain', 
                    'My pain is on the outside of my wrist (on the side that leads to my thumb)' : 'Lateral wrist pain',
                    'My pain is at the base of my thumb' : 'Pain at base of thumb',
                    'My pain is worsened when I lift anything heavy with my wrist' :'Wrist pain aggravated when lifting heavy objects',
                    'Pain is on both wrist' : 'Bilateral wrist pain', 
                    'My wrist is particularly tender' : 'Tender wrist joint',
                    'My pain doesn’t  have a particular spot (I cant pinpoint the affected area)' : 'Diffuse wrist pain'
                },
                Rom: {
            
                    'I find it aggravating if I write for a long time with affected hand' : 'Wrist pain aggravated by prolong writing',
                    'I cannot move my wrist fully in all directions' : 'Limited multidirectional AROM at wrist joint',
                    'Any movement of my wrist produce cracking or pop sound' : 'Crepitus at affected wrist joint', 
                    'My whole arm feels more weak than painful while moving it' : 'Affected arm feels more weak than painful',
                    'My wrist joint feels restricted and stiff' :'Stiff and restricted wrist joint',
                    'My pain is relieved when I stop moving my wrist' : 'Wrist pain is relieved by rest', 
                    'My pain is not relieved even when I stop moving my wrist ' : 'Wrist pain not relieved by rest',
                    'My wrist is often stiff for about an hour first thing in the morning' : 'Early morning stiffness on affected wrist joint'
                },
                Others:{
                    'My joint feels unstable' : 'Wrist joint fels unstable',
                    'I heard a crack when I fell on my wrist' : 'Cracking sound after fall on affected wrist', 
                    'I feel a shock or pins/needles running down to my fingers' : 'Paresthesia on affected hand and fingers',
                    'My pain started after I feel and landed on my hand' : 'Wrist pain started after a fall on affected hand',
                    'Resting on my palm makes my wrist pain worse' :'Wrist pain aggravated by weight bearing on affected arm',
                    'My wrist is swollen' : 'Swollen wrist', 
                    'I am over 50 years old': 'Over 50 years',
                    'I take over 15 bottles of alcohol weekly': 'Takes over 15 bottles of alcohol',
                },
            },
'Fingers':{
    flag: {
                
        'My pain is sudden and persistent' : 'Pain is of sudden onset and now persistent', 
        'I am experiencing the same symptom on both arms' : 'Bilateral symptoms',
        'My pain is triggered when I touch it, it is severe, constant and feels deep, like over a bone' : 'Severe, constant bony tenderness',
        'The affected joint is swollen, red and warm (or just warm and swollen)' :'Swollen, red and warm joints',
        'I am experiencing pain in my chest or/and my belly' : 'Chest or/and Belly pain', 
        'I am experiencing pins and needles or numbness down my arms (dead arm)' : 'Paresthesia down affected arm',
        'My joint feels stiff/stuck every morning for over 1 hour' : 'Early morning stiffness for over 1hr',
        'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
                  
        },  
           
        pain: {
        
            'My pain greater than 5 on a scale of 1-10' : 'Moderate to severe pain',
            'My pain lesser than 5 on a scale of 1-10' : 'Minor to moderate pain', 
            'My pain feels dull and aching ' : 'Dull and Achy pain',
            'My pain is does not get worse when I touch the affected area' : 'Pain not aggravated by palpation',
            'I have experienced similar symptoms before on the same body part.' :'Previous episodes of similar symptoms on same body part',
            'The pain  has been getting better since it started ' : 'Pain has been progressively improving', 
            'The pain has been getting progressively worse since it started ' : 'Pain has been progressively worsening',
            'I have had this symptom for a long time (over 6 months)' : 'Chronic pain'
                    
             },
                morepain: {
                    'My pain is just at the tip of my finger' : 'Pain at finger tip',
                    'My pain on at the base of my thumb (maybe swollen)' : 'Tenderness or a nodule at the base of the affected finger', 
                    'My pain is at the base of the affected finger  (maybe swollen)' : 'Tenderness or a nodule at the base of the pinky finger',
                    'My pain is only on one joint between my fingers' : 'Monoarticular finger pain',
                    'Pain is on almost all finger joints in both hands' :'Polyarticular finger pain',
                    'My fingers are usually stiff first thing in the morning for over 1 hours' : 'Early morning stiffness in the fingers for over 1 hour', 
                    'My fingers are usually stiff first thing in the morning for about 1 hour only ' : 'Early morning stiffness in the fingers for about an hour',
                    'My pain doesn’t  have a particular spot (I can’t pinpoint the affected area)' : 'Diffuse finger pain'
                },
                Rom: {
                    'I cannot make a tight fist' : 'Inability to make a fist',
                    'My finger looks deformed/abnormal' : 'Finger drooping or appearing deformed',
                    'Any movement of my fingers produce cracking or pop sound' : 'Snapping or popping sensation during finger movement', 
                    'I cannot bend or straighten thmore than one finger' :'Difficulty straightening one or more fingers',
                    'My finger is swolllen and or restricted and stiff' : 'Stiffness or swelling in the finger or thumb', 
                    'My pain is relieved when I stop moving my finger' : 'Finger pain relieved with rest',
                    'My pain is not relieved even when I stop moving my finger' : 'Finger pain is not relieved with rest',
                    'My finger locks/catch when I bend or straighten the affected finger' : 'Catching or locking sensation when bending or straightening the finger', 
                    'I cannot bend or straighten the affected finger ' : 'Difficulty fully extending or flexing the finger',
                },
                Others:{
                    'I pulled someone shirt during sport'  : 'Pulled a shirt during sport',
                    'I cannot place my hand flat on a surface' : 'Difficulty placing the hand flat on a surface',
                    'My pain started after I feel and landed on my hand' :'Finger pain started following a fall on affected hand',
                    'Pain started after I had an injury or forcefully move my finger': 'Sudden onset of symptoms following trauma or forceful finger movement',
                    'The palm of my hand is swollen/thickened': 'Thickening and formation of nodules or cords in the palm of the hand ',
                    'I cannot bend the finger joint closest to my palm':'Inability to fully straighten the finger at the end joint',
                    'I cannot grip or hold objects very well': 'Difficulty gripping or holding objects ',
                    'The affected finger is gradually bending towards my palm':'Progressive curling or bending of the affected fingers towards the palm'
                },
            },
               
    }
    
    
   
    export default body


