<template>

<header  >
    <div class="logo-section" >
             
         <router-link  to="/" class="logo">  
                    <logoVue/> 
                     <a class="home-link" href="" >Rehabify</a>  
         </router-link>
        
            
    </div>

    
  
    <div class="header-buttons"   >
        
       <span class="links">
       
        <li> <router-link  v-if="!auth" to='/find-a-physio'></router-link></li>
        <li v-if="!auth"> <router-link to='/gateway' ></router-link></li>
        <li v-if="!auth"> <router-link to= '/login'>Login</router-link></li>
        <li v-if="auth"> <router-link :to='thisUser'>Profile page</router-link></li> 
        <li @click="logout" v-if="auth"> <router-link to='/'>Logout</router-link></li>
       
       </span>

      
       <div class="menubar"   @click="(this.showMenu = !this.showMenu); changeMenu()">
 
        <!-- <img ref="menuimg" style="border: 3px solid white; padding: 0px;" src="../assets/icon/menu-svgrepo-com.svg"  alt="">
             -->
       
    </div>

      


    </div>

    <div class="menu"  v-show="showMenu" >
    <ul  >
        
        <li> <router-link  v-if="!auth" to='/find-a-physio'></router-link></li>
        <li v-show="!auth"> <router-link to='/gateway' ></router-link></li>
        <li v-if="!auth"> <router-link to= '/login'>Login</router-link></li>
        <li v-show="auth"> <router-link :to='thisUser'>My Profile</router-link></li> 
        <li @click="logout" v-show="auth"> <router-link to='/'>Logout</router-link></li>
       
     </ul>          
               
                
                  
</div>

</header>




</template>


<script>


  

import {auth,  signOut} from '../main'


import { mapGetters } from 'vuex';
import logoVue from './logo';


export default {

    name: 'header-component',
    components: {
        logoVue
    },

    data(){
        return{
            showMenu: false,
            homeActive: 'active',
            source: "@/assets/icon/menu-svgrepo-com.svg",
            auth: JSON.parse(sessionStorage.getItem('vuex')).currentUser.isAuthenticated,
            thisUser: ''
           
    
        }
    },
   
   mounted(){
    if(this.auth){
       let loggedinUser= JSON.parse(localStorage.getItem('User')).FirstName
      
       this.thisUser= `/profile/:${loggedinUser}`
     
    }
    else{
        this.thisUser= `/profile/:user`
    }
   },  
    computed:{
        ...mapGetters(["getAuth"])
    },

   
    methods:{

        changeMenu(){

           if(this.showMenu){
            this.$refs.menuimg.src= require("@/assets/icon/cross-svgrepo-com.svg")
           }
           else{
            this.$refs.menuimg.src= require("@/assets/icon/menu-svgrepo-com.svg")
           }
          
        },
       async logout(){
           try{ 
              await signOut(auth)
              window.location.replace('/')
               sessionStorage.clear()
                   localStorage.clear()
                   this.$router.replace({'name': '/home'})
           }catch(err){
                console.log(err)

           }
           

        },

      
    
    }
   
}
</script>


<style scoped>





</style>