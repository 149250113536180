
const state = {
    physioUser:{},
    patientUser:{},
   signupErr: localStorage.getItem('error'),
   signupErrMess: null
}

const getters = {
    getError(){
        return state.signupErr
    }
}

const actions = {
     // post signed up Physio User's data to the Database
        getPhysio({commit}, user){
             
               
                commit ('newPhysio', user)
               
             },
     // post signed up Patient User's data to the Database      
        getPatient({commit}, user){
            commit ('newPatient', user)

            }   

}

const mutations = {
    newPhysio: (state, user)=> {
       
            state.patientUser= user
            state.signupErr = false
            alert('no error')
        },

      
    newPatient: (state, user) => (state.patientUser = user)
}













export default {
    state,
    getters,
    actions,
    mutations
}